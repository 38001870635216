import moment from 'moment';
import React from 'react';
import { maskCep, maskCpf, maskPhone, maskCardNumber } from '../../utils';
import { StyledInput, StyledLabel } from './styles';

const maxLengthByType = {
	'tel': 14,
	'cpf': 14,
	'card-expiration': 5,
	'card-cvv': 4,
	'card-number': 19,
	'cep': 9
}

const correctType = {
	'card-expiration': 'tel',
	'card-cvv': 'tel',
	'card-number': 'tel',
	'cpf': 'tel',
	'cep': 'tel',
}

function validCpf(cpf) {
	cpf = cpf.replace(/[^\d]+/g, '');
	if (cpf === '') {
		return false;
	}
	// Elimina CPFs invalidos conhecidos
	if (
		cpf.length !== 11 ||
		cpf === '00000000000' ||
		cpf === '11111111111' ||
		cpf === '22222222222' ||
		cpf === '33333333333' ||
		cpf === '44444444444' ||
		cpf === '55555555555' ||
		cpf === '66666666666' ||
		cpf === '77777777777' ||
		cpf === '88888888888' ||
		cpf === '99999999999'
	) {
		return false;
	}
	// Valida 1o digito
	let add = 0;
	for (let i = 0; i < 9; i++) {
		add += parseInt(cpf.charAt(i), 10) * (10 - i);
	}
	let rev = 11 - (add % 11);
	if (rev === 10 || rev === 11) {
		rev = 0;
	}
	if (rev !== parseInt(cpf.charAt(9), 10)) {
		return false;
	}
	// Valida 2o digito
	add = 0;
	for (let i = 0; i < 10; i++) {
		add += parseInt(cpf.charAt(i), 10) * (11 - i);
	}
	rev = 11 - (add % 11);
	if (rev === 10 || rev === 11) {
		rev = 0;
	}
	if (rev !== parseInt(cpf.charAt(10), 10)) {
		return false;
	}
	return true;
}

function validCardExpiration(date) {
	if (date.length >= maxLengthByType['card-expiration']) {
		const correctDate = date.split('/');
		correctDate[1] = `20${correctDate[1]}`;
		const mDate = moment(`${correctDate.reverse().join('-')}-01`);

		// Não é uma data válida
		if (!mDate.isValid()) { return false; }

		// Verifica se é uma data passada
		var diffMonths = moment().diff(mDate, 'months');

		if (diffMonths > 0) {
			return false;
		}

		return true;
	}

}

class Input extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = { value: props.value || '', valid: false }
		this.inputRef = React.createRef();
	}

	componentDidMount() {
		this._setValidity();
	}

	_setValidity() {
		const { type } = this.props;
		const { value } = this.state;

		let isValid = true;

		switch (type) {
			case 'card-expiration':
				isValid = validCardExpiration(value);
				break;
			case 'tel':
				isValid = value.length === maxLengthByType[type];
				break;
			case 'cpf':
				isValid = validCpf(value);
				break;
			case 'email':
				const testEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				isValid = testEmail.test(value);
				break;
			default:
				isValid = !!value.length;
				break;
		}

		this.setState({ valid: isValid });
	}

	_onChange(evt) {
		const { onChange, type } = this.props;

		let evtValue = evt.target.value || '';

		switch (type) {
			case 'card-number':
				evtValue = maskCardNumber(evtValue)
				break;
			case 'tel':
				evtValue = maskPhone(evtValue);
				break;
			case 'cpf':
				evtValue = maskCpf(evtValue);
				break;
			case 'cep':
				evtValue = maskCep(evtValue);
				break;
			case 'email':
				evtValue = evtValue.toLowerCase();
				break;
			case 'card-expiration':
				evtValue = evtValue.replace(/\D/g, '');
				evtValue = evtValue.replace(/^(\d{2})(\d)/g, '$1/$2');
				evtValue = evtValue.replace(/(\d)(\d{2})$/, '$1/$2');
				break;
			default:
				break;
		}

		this.value = evtValue;
		return onChange ? onChange(evtValue) : null;
	}

	get value() {
		return this.state.value;
	}

	get name() {
		return this.props.name || '';
	}

	set value(val) {
		return this.setState({ value: val }, this._setValidity)
	}

	get valid() {
		return this.state.valid;
	}

	get showAs() {
		return this.props.showAs || '';
	}

	get required() {
		return !this.props.notRequired;
	}

	get ref() {
		return this.inputRef.current;
	}

	focus() {
		return this.inputRef.current.focus()
	}

	render() {
		const { type, black = false, onFocus, onBlur, label = null, name = '', showBorderValid = false } = this.props;
		const { valid } = this.state;
		return (
			<>
				{label && (
					<StyledLabel htmlFor={name}>{label}</StyledLabel>
				)}
				<StyledInput
					{...this.props}
					onFocus={(e) => {
						if (onFocus) {
							onFocus(e);
						}
					}}
					onBlur={(e) => {
						if (onBlur) {
							onBlur(e, this.state.value);
						}
					}}
					ref={this.inputRef}
					className={`${black ? 'black__input' : ''} ${showBorderValid ? 'show-border-valid' : ''} ${valid ? 'valid' : 'invalid'} ${this.props.className || ''}`}
					maxLength={maxLengthByType[type] || '200'}
					onChange={this._onChange.bind(this)}
					value={this.state.value}
					autoComplete={type === 'password' ? 'off' : null}
					type={correctType[type] || type || 'text'}
					label={label}
				/>
			</>
		)
	}
}

export default Input;