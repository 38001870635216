import styled from 'styled-components';
import { darkGrey, GREY, white, FONTS, BLUE } from '../../constants/colors';
import { remSizes } from '../../styles/general';

export const StyledLabel = styled.label`
    color: ${FONTS.inputLabel};
    font-size: ${remSizes['12']}rem;
    font-weight: 700;
`;

export const StyledInput = styled.input`
    width: 100%;
    border: 1px solid ${GREY.borderGrey};
    border-radius: ${props => props.rounded ? '50' : '4'}px;
    padding: 13px;
    margin-bottom: 10px;
    /* font-size: ${remSizes['14']}rem; */
    font-size: 14px;
    height: 40px;

    -webkit-appearance: none;

    ${props => props.center ? 'text-align: center;' : ''};
    ${props => props.center ? 'text-align: center;' : ''};
    ${props => props.label ? 'margin-top: 4px;' : ''};

    &.black__input{
        border: 1px solid ${white};
        background: ${darkGrey};
        color: ${white};
    }

    &:disabled{
        opacity: .8;
    }

    &::placeholder {
        color: #CFD8DC;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #CFD8DC;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: #CFD8DC;
    }

    &.show-border-valid.valid{
        border-color: ${BLUE.dark};
    }
`;