// className={`wrapper-content ${showResume ? 'with-resume-on-bottom' : ''} ${showLargeContent ? 'large-size' : ''}`}

import React, { lazy, Suspense, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { CartContext } from '../../contexts/CartContext'
import { SidebarContext } from '../../contexts/SidebarContext';


const Whatsapp = lazy(() => import('../Whatsapp'));

export default function MainContentContainer(props) {
    const { showResume } = useContext(CartContext);
    const { showLargeContent } = useContext(SidebarContext);
    const [hasPadding, setHasPadding] = useState(false);

    let location = useLocation();

    useEffect(() => {
        setHasPadding(!!showResume);
        return () => { }
    }, [location, showResume])

    return (
        <div id="container-main-content" className={`wrapper-content ${hasPadding ? 'with-resume-on-bottom' : ''} ${showLargeContent ? 'large-size' : ''}`}>
            {props.children}

            <Suspense fallback={<div></div>}>
                <Whatsapp className={`${hasPadding ? 'with-resume-on-bottom' : ''}`} />
            </Suspense>
        </div>
    )
}
