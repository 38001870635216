import React from 'react';
import { ButtonLoading, ImageLoading, TextLoading } from './styles';

export const ButtonSkeleton = (props) => {
    return (
        <ButtonLoading {...props}/>
    );
};

export const ImageSkeleton = (props) => {
    return (
        <ImageLoading {...props}/>
    );
};

export const TextSkeleton = (props) => {
    return (
        <TextLoading {...props}/>
    );
};
