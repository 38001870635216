/* eslint-disable require-jsdoc */
import 'pure-react-carousel/dist/react-carousel.es.css';
import React, { useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from 'styled-components';
import { stage, version } from '../config';
import { storageCart, storageUser } from '../constants/strings';
import Context from './AppContext';
import HeaderContextProvider from './HeaderContext';
import SidebarContextProvider from './SidebarContext';
import CartContextProvider from './CartContext';
import StoreContextProvider from './StoreContext';
import { red } from '../constants/colors';

import moment from 'moment';
import Restaurant from '../apis/Restaurant';

export default function ContextsProvider({ children }) {
    const [user, setUser] = useState(null);
    const [store, setStore] = useState(null);
    const [storeList, setStoreList] = useState(null);
    const [cart, setCart] = useState(null);
    const [loading, setLoading] = useState(null);
    const [theme, setTheme] = useState({ color: red })
    const [title, setTitle] = useState({ text: null, image: null });
    const [cartInfo, setCartInfo] = useState(null);
    const [showDetailCart, setShowDetailCart] = useState(false);
    const [opened, setOpened] = useState(false);
    const [filterType, setFilterType] = useState('store');
    const [showResume, setShowResume] = useState(false);
    const [showLargeContent, setShowLargeContent] = useState(false);

    useEffect(() => {
        window.doLog('v', version);

        async function _getStores() {
            const { data } = await Restaurant.get('/store');
            const useStores = data.stores || [];
            setStoreList(useStores);
        }

        const strUserStorage = window.localStorage.getItem(storageUser);
        if (strUserStorage) {
            const parsedUser = JSON.parse(strUserStorage);
            setUser(parsedUser);
        }

        const cartInfoString = window.localStorage.getItem(storageCart);
        if (cartInfoString) {
            const cartObj = JSON.parse(cartInfoString);
            if (cartObj) {
                const expired = cartObj.expires ? !!(cartObj.expires <= (new Date().getTime())) : false;
                const currCart = expired ? null : cartObj || null;
                window.doLog(`Carrinho carregado`, currCart);
                window.doLog(`Carrinho Expirado?`, expired);
                setCart(currCart);
            }
        }

        _getStores();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stage !== 'prod') {
            window.doLog('user change', user);
        }

        if (user) {
            window.localStorage.setItem(storageUser, JSON.stringify(user))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (cart) {
            const momentExpires = moment().add(31, 'days').format('YYYY-MM-DD');
            const expires = new Date(momentExpires);
            expires.setHours(0, 0, 0);
            cart.expires = expires.getTime();
            window.doLog('set cart', cart);
            window.localStorage.setItem(storageCart, JSON.stringify(cart))
        }

        else {
            window.doLog('remove cart', cart);
            window.localStorage.removeItem(storageCart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);

    const appState = {
        user,
        setUser,
        cart,
        setCart,
        loading,
        setLoading,
        theme,
        setTheme
    };

    return (
        <CookiesProvider>
            <Context value={appState}>
                <CartContextProvider value={{ cartInfo, setCartInfo, showResume, setShowResume }}>
                    <StoreContextProvider value={{ store, setStore, storeList, setStoreList, filterType, setFilterType }}>
                        <SidebarContextProvider value={{ opened, setOpened, showLargeContent, setShowLargeContent }}>
                            <HeaderContextProvider value={{ title, setTitle, showDetailCart, setShowDetailCart }}>
                                <ThemeProvider theme={theme}>
                                    {children}
                                </ThemeProvider>
                            </HeaderContextProvider>
                        </SidebarContextProvider>
                    </StoreContextProvider>
                </CartContextProvider>
            </Context>
        </CookiesProvider>
    )
}
