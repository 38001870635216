import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { isSafari, path, stage } from './config';

window.doLog = (text = '', data = {}) => {
    if (stage !== 'prod') {
        console.log(text, data);
    }
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

if ('serviceWorker' in navigator && !isSafari) {
    // console.log('stage', stage)
    if (stage !== 'dev') {
        navigator.serviceWorker.register(`${path}/service-worker.js`, { scope: `${path}` })
            .then(function (reg) {
                // console.log('reg', reg);
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    // navigator.serviceWorker.getRegistrations().then(function (registrations) {
    //     for (let registration of registrations) {
    //         registration.unregister()
    //     }
    // })
}