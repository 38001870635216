export const black = '#000000';
export const white = '#FFFFFF';
export const grey = '#B9B9B9';
export const darkGrey = '#2E2E2E';
export const lightGrey = '#e5e3e3';
export const lightYellow = '#F9EEDC';
export const red = '#CE262C';

export const GREY = {
    light: '#ECEFF1',
    medium: '#FAFAFA',
    strong: '#90A4AE',
    borderGrey: '#B0BEC5',
}

export const BLUE = {
    dark: '#204982'
}

export const FONTS = {
    main: '#263238',
    inputLabel: '#607D8B'
}
