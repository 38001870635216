/* eslint-disable require-jsdoc */
import 'pure-react-carousel/dist/react-carousel.es.css';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle, { headerHeight } from './styles/general';
import Routes from './routes';
import { Wrapper } from './styles/general';
import Div100vh from 'react-div-100vh'
import Sidebar from './components/Sidebar';
import CartDetails from './components/CartDetails';
import StoreFilter from './components/StoreFilter';
import ContextsProvider from './contexts';

import './styles/base.css';
import './assets/fonts/Radley-Regular.ttf';
import './assets/fonts/Radley-Italic.ttf';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import moment from 'moment';
import 'moment/locale/pt-br';
import MainContentContainer from './components/MainContentContainer';
import ScrollWrapper from './components/ScrollWrapper';
moment.locale('pt-br');

const Header = lazy(() => import('./components/Header'));
const Footer = lazy(() => import('./components/Footer'));
const CartResume = lazy(() => import('./components/CartResume'));

const App = () => {
    return (
        <>
            <GlobalStyle />
            <ContextsProvider>
                <Router >
                    <Suspense fallback={<div id="main-header" style={{
                        height: headerHeight
                    }}></div>}>
                        <Header />
                    </Suspense>

                    <Div100vh>
                        <ScrollWrapper id="content-scroll-control"
                            style={{
                                position: 'relative',
                                top: headerHeight,
                                width: '100%',
                                height: `calc(100% - ${headerHeight}px)`,
                                overflowY: 'auto',
                            }}>
                            <Wrapper className="content__wrapper">
                                <Sidebar />
                                <div className="wrapper-main-content">
                                    <MainContentContainer>
                                        <ScrollWrapper id="wrapper-content" content>
                                            <StoreFilter />
                                            <Routes />
                                            <Suspense fallback={<div></div>}>
                                                <CartResume hideOnCartPage/>
                                                <Footer mobile />
                                            </Suspense>
                                        </ScrollWrapper>
                                    </MainContentContainer>
                                    <CartDetails className={'wrapper-cart'} screen />
                                </div>
                            </Wrapper>

                            <Suspense fallback={<div></div>}>
                                <Footer desktop />
                            </Suspense>
                        </ScrollWrapper>
                    </Div100vh>

                </Router>
            </ContextsProvider>

        </ >
    );
};

export default App;
