const stg = process.env.REACT_APP_STAGE;
const key = process.env.REACT_APP_API_KEY;
const endpoint = process.env.REACT_APP_API_ENDPOINT;
const staticPath = process.env.REACT_APP_STATIC_URL;
const ver = process.env.REACT_APP_VERSION;
const secret = process.env.REACT_APP_SECRET_REQUEST;

export const isSafari = /constructor/i.test(window.HTMLElement) ||
    (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification));

export const stage = stg;
export const path = ``;
export const apiKey = key;
export const endpointApi = endpoint;
export const staticUrl = staticPath;
export const version = ver;
export const secretRequest = secret;
