import styled from "styled-components";
import { BLUE, FONTS } from "../../constants/colors";
import { Button } from "../../styles/general";

export const LoginWrapper = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: ${FONTS.inputLabel};

    a, a:visited {
        color: ${FONTS.inputLabel};
        font-size: 12px;
    }

    h3 {
        margin-bottom: 16px;
    }

    .or {
        margin-top: 16px;
    }
`;

export const ButtonLogin = styled(Button)`
    background: ${BLUE.dark};
    max-width: 100%;
    margin-top: 6px;
`;