/* eslint-disable require-jsdoc */
import React, { createRef, lazy, Suspense, useContext, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import Restaurant from '../../apis/Restaurant';
import { path } from '../../config';
import { AppContext } from '../../contexts/AppContext';
import { validateFields } from '../../utils';
import { ButtonLogin, LoginWrapper } from './styles';

const Alert = lazy(() => import('../../components/Alert'));
const Input = lazy(() => import('../../components/Input'));

const Login = ({ history, onLogin = () => { }, onLoginError = null, register = true, onRegister = () => {}, onResend = () => {} }) => {
    const { setLoading } = useContext(AppContext)
    const alertRef = useRef(<Alert />);

    const mailInput = createRef(null);
    const passInput = createRef(null);

    async function _onSubmitForm(evt) {
        evt.preventDefault();

        const fields = [
            mailInput.current,
            passInput.current,
        ];

        const { valid, errors, form } = validateFields(fields);

        if (!valid) {
            return alertRef.current.show('Algo deu errado', `Verifique o campo ${errors[0].showAs}!`);
        }

        setLoading(true);

        const { data } = await Restaurant.post('/user', form);

        setLoading(false);

        if (!data.success || !data.user || !data.token) {
            if (onLoginError) {
                return onLoginError(data.error || 'Não foi possível realizar o login. Tente novamente');
            }

            return alertRef.current.show('Algo deu errado', `${data.error || 'Não foi possível realizar o login. Tente novamente'}`);
        }

        return onLogin(data);
    }

    function _onClickRegister(){
        onRegister();
        return history.push(`${path}/cadastro`);
    }

    function _onClickResendPass(){
        onResend();
        return history.push(`${path}/senha`);
    }

    return (
        <LoginWrapper>
            <div>
                <h3>Seja bem-vindo</h3>
                <span className="">Informe os campos abaixo para efetuar o login</span>
                <Suspense fallback={<div></div>}>
                    <form onSubmit={_onSubmitForm} style={{ marginTop: '16px' }}>
                        <Input ref={mailInput} type="email" showAs="E-mail" name="email" placeholder="E-mail" />
                        <Input ref={passInput} type="password" showAs="Senha" name="password" placeholder="Senha" />
                        <ButtonLogin type="submit">Efetuar Login</ButtonLogin>
                        <center>
                            <div onClick={_onClickResendPass} style={{ marginTop: '8px', textDecorationLine: 'underline', cursor: 'pointer' }}>Esqueci minha senha</div>
                        </center>
                    </form>
                </Suspense>
            </div>

            <div>
                <center>
                    {!!register && (
                        <>
                            <div className="or"><span>ou</span></div>
                            <ButtonLogin onClick={_onClickRegister} blue>Cadastrar-se</ButtonLogin>
                        </>
                    )}
                </center>
            </div>
            <Suspense fallback={<div></div>}>
                <Alert ref={alertRef} />
            </Suspense>
        </LoginWrapper>
    );
};

export default withRouter(Login);
