import React, { useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { isSafari } from '../config';
import { FONTS, GREY, lightYellow, white } from '../constants/colors';
import { AppContext } from '../contexts/AppContext';

export const breakpoints = {
    xlg: 1200,
    lg: 990,
    md: 768,
    sm: 480,
    xsm: 370
};

export const remSizes = {
    '40': 2.45,
    '22': 1.57,
    '20': 1.42,
    '18': 1.28,
    '16': 1.14,
    '14': 1,
    '12': 0.85,
    '10': 0.71,
};

export const headerHeight = 60;

export const subHeaderHeight = 60;

export const defaultContentPadding = 16;

export const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    position: relative;

    display: flex;
    height: 100%;

    .wrapper-main-content {
        display: flex;
        flex: 1;
        background-color: #fff;

        .wrapper-content{
            flex: 0 0 730px;
            overflow: hidden;
            -webkit-overflow-scrolling: auto !important;
            max-width: 730px;

            @media screen and (min-width: ${breakpoints.md}px){
                &.large-size {
                    flex: 1 1 45%;
                    max-width: 965px;
                }
            }

        }

        .wrapper-cart{
            flex: 1 1;
            overflow: hidden auto;
            background-color: ${GREY.medium};
            border-left: 1px solid ${GREY.light};
            -webkit-overflow-scrolling: auto!important;
        }
        
        @media screen and (max-width: ${breakpoints.lg}px){
            .wrapper-content{
                flex: 1;
                max-width: 100vw;
                padding-bottom: 0;
            }

            .wrapper-cart{
                display: none;
            }
        }
    }
`;

export const StyledContentWrapper = styled.div`
    width: 100%;
    position: relative;
    margin: 0 auto;
    padding: ${(props) =>
        (props.paddingTop || props.paddingTop === 0) ? props.paddingTop : 0}px 15px ${(props) => (props.paddingBottom || props.paddingBottom === 0) ? props.paddingBottom : 60}px;

    ${props => props.noMinHeight ? '' : `
        min-height: calc(100vh - ${headerHeight}px);
    `}
    
    &.user-pages {
        max-width: 490px;

        &.large {
            max-width: 745px;
        }
    }
    
    @media screen and (min-width: ${breakpoints.md}px){
        max-width: 1086px;

        &.small {
            max-width: 768px;
        }
    }

    @media screen and (max-width: ${breakpoints.md}px){
        &.safari-browser{
            padding-bottom: ${(props) => (props.paddingBottom || props.paddingBottom === 0) ? props.paddingBottom : 110}px
        }

        &.fix-width-mobile{
            max-width: 325px;
            
            &.fix-child {
                max-width: 100%;
                
                .fixed-item {
                    max-width: 325px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

`;

export const ContentWrapper = (props) => {
    return (
        <StyledContentWrapper {...props}
            className={`${props.className} ${isSafari ? 'safari-browser' : ''}`} />
    )
}

export const Title = styled.h1`
    color: ${props => props.theme.color};
    /* font-weight: bold; */
    text-align: center;
    margin-bottom: ${(props) => (props.marginBottom || props.marginBottom === 0 ? props.marginBottom : '25')}px;
`;

export const StyledButton = styled.button`
    background-color: ${props => props.transparent ? 'transparent' : props.noTheme ? FONTS.main : props.theme.color};
    color: ${props => props.transparent ? FONTS.main : white};
    text-align: center;
    padding: 0px 15px;
    width: 100%;
    border-radius: 50px;
    height: 40px;
    max-width: 245px;
    font-weight: bold;
    /* box-shadow: 1px 1px 4px 0px rgba(0,0,0,.4); */

    ${props => props.transparent ? `border: 1px solid ${FONTS.main};` : ''}

    @media screen and (min-width: ${breakpoints.md}px){
        max-width: 280px;
    }

    &.loading{
        cursor: not-allowed;
        opacity: 0.7;
    }
`;

export const Button = (props) => {
    const { loading } = useContext(AppContext);

    return (
        <StyledButton
            onClick={(evt) => {
                if (loading) { return null; }
                if (props.onClick) { return props.onClick(evt) }
                return null;
            }}
            {...props}
            disabled={(loading || props.disabled) ? 'disabled' : null}
            className={`${props.className || ''} ${loading ? 'loading' : ''}`} />
    )
}

export const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: ${(props) => (props.marginBottom || '0')}px;
`;

export const Image = (props) => {
    return (
        <StyledImage {...props} alt={props.alt || 'image'} />
    )
}

export const Section = styled.section`
    margin-bottom: 50px;
`;

const GlobalStyle = createGlobalStyle`

    #root{
        &.hide-cart {
            #container-main-content {
                flex: 1;
                max-width: 100%;
            }

            .wrapper-cart {
                display: none;
                flex: 0
            }
        }
    }

    .filter-bar {
        width: 100%;
        background-color: ${lightYellow};
        padding: 20px ${defaultContentPadding}px;
        font-weight: 700;
    }

    .main-content-padding {
        &.horizontal{ 
            padding-left: 25px;
            padding-right: 25px;
        }
        
        &.vertical {
            padding-top: 25px;
            padding-bottom: 25px;
        }

        @media screen and (max-width: ${breakpoints.sm}px){
            &.horizontal{ 
                padding-left: 16px;
                padding-right: 16px;
            }
            
            &.vertical {
                padding-top: 16px;
                padding-bottom: 16px;
            }
        }
    }

    .default-padding {
        &.horizontal{ 
            padding-left: ${defaultContentPadding}px;
            padding-right: ${defaultContentPadding}px;
        }
        
        &.vertical {
            padding-top: ${defaultContentPadding}px;
            padding-bottom: ${defaultContentPadding}px;
        }
    }

    .image__cover {
        height: 40vh!important;
        @media screen and (min-width: ${breakpoints.md}px){
            height: 50vh!important;
        }
        @media screen and (min-width: 1440px){
            height: 60vh!important;
        }
    }

    .session-header {
        background-color: ${GREY.medium};
        padding: 23px ${defaultContentPadding}px;
        width: 100%;
        
        span {
            font-weight: 700;
        }
    }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .carousel-height {
        height: 275px;

        @media screen and (max-width: 768px){
            height: 140px;
        }
    }

    .image-carousel-radius {
        border-radius: 8px;
    }

    .subheader-height {
        height: ${subHeaderHeight}px;
    }

    #container-main-content {
        @media screen and (max-width: ${breakpoints.md}px){
            &.with-resume-on-bottom {
                padding-bottom: ${headerHeight}px;
            }
        }
    }
    
    #wrapper-content {
        overflow: hidden auto;
        height: 100%;

        @media screen and (max-width: ${breakpoints.md}px){
            &.stop-scroll {
                overflow: hidden!important;
            }
        }
    }

    .ps--active-y > .ps__rail-y {
        z-index: 4;
    }

    @media screen and (max-width: ${breakpoints.lg}px){
        .fix-mobile-index {
            z-index: -1!important;
        }

        .hide-mobile {
            display: none!important;
        }
    }

    @media screen and (min-width: ${breakpoints.lg}px){
        .hide-desktop {
            display: none!important;
        }
    }
`;

export default GlobalStyle;