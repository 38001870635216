import styled, { keyframes } from "styled-components";
import { BLUE, FONTS, GREY } from "../../constants/colors";
import { breakpoints } from "../../styles/general";

export const SidebarContainer = styled.div`
    flex: 0 0 234px;
    transition: all .2s;
    background-color: #fff;
    border-right: 1px solid ${GREY.light};
    z-index: 5;
    position: relative;

    @media screen and (max-width: ${breakpoints.xlg}px){
        position: fixed;
        width: 234px;
        height: 100%;
        left: -300px;
        top: 0;
        
        &.opened {
            left: 0;
        }
    }

    @media screen and (min-width: ${breakpoints.xlg}px){
        &.hide {
            display: none!important;
        }
    }

    .scroll-sidebar {
        height: 100%;
        overflow: hidden auto;

        &.with-user {
            height: calc(100% - 50px);
        }
    }
`;

export const LoginContainer = styled.div`
    width: 100%;
    height: 94px;
    border-bottom: 1px solid ${GREY.light};
    padding: 20px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    .menu-profile-image {
        width: 56px;
        height: 56px;
        border-radius: 56px;
        margin-right: 8px;
    }

    .menu-container-texts {
        color: ${FONTS.inputLabel};
        
        .login-text {
            color: ${BLUE.dark};
            text-decoration: underline;
            margin-top: 3px;
        }
    }

    .show-cart-info {
        display: flex;
        align-items: center;
        margin-top: 5px;

        span { 
            color: #78909C;
            margin-left: 2px;
        }

        svg {
            width: 12px;
            height: 12px;
        }
    }
`;

const layerSubItemsAnimation = keyframes`
    0%{
        opacity: 0;
        -webkit-opacity: 0;
    }
    100% {
        opacity: 1;
        -webkit-opacity: 1;
    }
`;

export const ListLinks = styled.ul`

    li.sidebar-layer {
        cursor: pointer;
        border-bottom: 1px solid ${GREY.light};

        .layer-infos {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 15px;
        }

        .container-texts {
            margin-left: 10px;
            flex: 1;
            
            .label-item {
                font-weight: 700;
                text-transform: uppercase;
            }
    
            .description-item {
                font-size: 10px;
                line-height: 11px;
                color: #78909C;
                margin-top: 2px;
            }
            
            .container-type {
                margin-top: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                span { margin-left: 5px; text-transform: uppercase; }
            }
        }

        .arrow-down-item {
            transition: all .3s;
            transform: rotateZ(-90deg);
        }

        .stores-item {
            display: none;
            cursor: pointer;
            border-bottom: 1px solid ${GREY.light};
            background-color: #FAFAFA;

        }

        &.opened {
            border-bottom: none;

            .arrow-down-item {
                transform: rotateZ(0deg);
            }

            .stores-item {
                display: block;
                animation: ${layerSubItemsAnimation} .5s ease-in-out;
            }
        }

        &.profile-button {
            color: ${FONTS.inputLabel};
            font-size: 14px;
            transition: all .2s ease-in-out;

            .label-item{
                text-transform: none;
            }

            &.active {
                background-color: ${FONTS.inputLabel};
                color: white;
            }
        }
    }
    
    li.sidebar-store {
        cursor: pointer;
        border-bottom: 1px solid ${GREY.light};

        &:first-of-type{
            border-top: 1px solid #ECEFF1;
        }

        .store-infos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px 15px 18px 24px;
        }

        .store-name {
            margin-left: 10px;
            flex: 1;
            font-weight: 700;
        }

        .store-arrow-down {
            transition: all .3s;
            transform: rotateZ(-90deg);
        }

        .store-categories {
            display: none;
            cursor: pointer;
            border-bottom: 1px solid ${GREY.light};
            background-color: #FAFAFA;

        }

        &.opened {
            border-bottom: none;
            
            .store-arrow-down {
                transform: rotateZ(0deg);
            }

            .store-categories {
                display: block;
                animation: ${layerSubItemsAnimation} .5s ease-in-out;
            }
        }
    }
    
    li.sidebar-store-category {
        cursor: pointer;
        border-bottom: 1px solid ${GREY.light};

        &:first-of-type{
            border-top: 1px solid #ECEFF1;
        }

        .category-infos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px 15px 18px 30px;
        }

        .category-name {
            margin-left: 10px;
            flex: 1;
            font-weight: 700;
            color: #78909C;
        }
    }
`;

export const SidebarBackground = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.4);
    z-index: 4;
    cursor: pointer;
    display: none;

    &.opened {
        display: block;
        animation: ${layerSubItemsAnimation} .5s ease-in-out;
    }
`;

export const LogoutContainer = styled.div`
    background: white;
    cursor: pointer;
    border-top: 1px solid ${GREY.light};
    padding: 15px;
    color: #F44336;
    width: 100%;

    /* position: absolute;
    bottom: 0;
    left: 0; */
`;