import React from 'react';

export const SidebarContext = React.createContext({
    opened: null,
    setOpened: () => { },
    showLargeContent: null,
    setShowLargeContent: () => { },
});

const SidebarContextProvider = ({ children, value }) => (
    <SidebarContext.Provider value={value}>
        <SidebarContext.Consumer>
            {() => children}
        </SidebarContext.Consumer>
    </SidebarContext.Provider>
);

export default SidebarContextProvider;
