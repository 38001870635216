import React from 'react';

export const CartContext = React.createContext({
    cartInfo: null,
    setCartInfo: () => {},
    showResume: false,
    setShowResume: () => {}
});

const CartContextProvider = ({ children, value }) => (
    <CartContext.Provider value={value}>
        <CartContext.Consumer>
            {() => children}
        </CartContext.Consumer>
    </CartContext.Provider>
);

export default CartContextProvider;
