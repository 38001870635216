import styled from "styled-components";
import { BLUE, FONTS, GREY, lightYellow, white } from "../../constants/colors";
import { breakpoints, defaultContentPadding, headerHeight } from "../../styles/general";
import ScrollWrapper from "../ScrollWrapper";


export const CartDetailsContainer = styled.div`
    width: 100%;
    cursor: default;
    box-shadow: rgba(0, 0, 0, 0.4) 0px -2px 5px 0px;

    display: flex;
    flex-direction: column;

    & > div:first-of-type{
        flex: 1;
        overflow-y: auto;
    }

    &.modal {
        max-width: 410px;
        max-height: 75vh;
    }

    &.screen{
        box-shadow: none;
        height: 100%;
    }

    .detail__cart__padding__content{
        background: ${white};
    }

    .detail__cart__header {}

    .detail__cart__orders {
        & > ul > li{
            border-bottom: 1px solid ${GREY.light};
            padding-bottom: 17px;
            padding: ${defaultContentPadding}px;
        }
    }

    .detail__cart__coupon {
        padding: ${defaultContentPadding}px;
        padding-bottom: ${2 * defaultContentPadding}px;
        
        .header-coupon {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            
            span {
                margin-left: 10px;
                color: ${FONTS.inputLabel};
            }
        }

        input {
            margin-bottom: 0;
        }
        
        button { 
            max-width: 88px;
            margin-left: 8px;
            background-color: ${FONTS.main};
        }

        & > div{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 13px;
        }

        .remove__coupon {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            color: ${FONTS.inputLabel};
            margin-top: 15px;

            .btn-remove {
                display: flex;
                justify-content: center;
                align-items: center;
                color: ${FONTS.main};
                font-weight: 700;
            }
        }
    }

    .detail__cart__suggestions{
        background-color: ${GREY.medium};

        .suggestions__header {
            padding: 23px ${defaultContentPadding}px;

            span {
                font-weight: 700;
                /* color: ${props => props.theme.color} */
            }
        }
        
        .container__carousel__suggestions{
            padding: 0 ${defaultContentPadding}px;
            
            .container__infos__carousel__suggestions{
                margin: 0 4px;
                border-radius: 4px;
                overflow: hidden;
                background-color: white;
                border: 1px solid #CFD8DC;
                
                img {
                    height: 110px;
                }
                
                .product-info {
                    padding: 6px 6px 10px;
                    position: relative;
                    
                    .container__infos__carousel__product-name {
                        color: ${BLUE.dark};
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .container__infos__carousel__product-price {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
    
                    .price-from {
                        font-size: 9px;
                        line-height: 1em;
                        color: #727272;
                        margin: 6px 0 3px;
                    }

                    .sug-fake-button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: ${BLUE.dark};
                        width: 26px;
                        height: 26px;
                        border-radius: 26px;
                        color: white;
                        position: absolute;
                        bottom: 8px;
                        right: 8px;
                        cursor: pointer;
                    }
                }

            }
        }
    }

`;

export const CartDetailsSubHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CartTotal = styled.div`
    background: ${lightYellow};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${defaultContentPadding}px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    z-index: 2;
    
    button {
        background: ${BLUE.dark};
        max-width: 96px;
    }

    @media screen and (max-width: ${breakpoints.lg}px){
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }
`;

export const CartEmpty = styled.div`
    height: calc(100% - ${headerHeight}px);
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${FONTS.inputLabel};
`;

export const CartEmptyScrollWrapper = styled(ScrollWrapper)``;