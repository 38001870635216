import React, { lazy, Suspense, useContext, useEffect, useRef, useState } from 'react'
import { ListLinks, LoginContainer, LogoutContainer, SidebarBackground, SidebarContainer } from './styles'
import { path } from '../../config';

import { matchPath, useHistory, useLocation } from 'react-router-dom'

import { ReactComponent as HomeIconSvg } from '../../assets/images/home-icon.svg';
import { ReactComponent as StoreIconSvg } from '../../assets/images/store-icon.svg';
import { ReactComponent as RestaurantIconSvg } from '../../assets/images/restaurant-icon.svg';
import { ReactComponent as ArrowDownSvg } from '../../assets/images/menu-arrow-down.svg';

import { ReactComponent as DeliverySvg } from '../../assets/images/delivery.svg';
import { ReactComponent as DeliveryScheduledSvg } from '../../assets/images/delivery-scheduled.svg';

import Restaurant from '../../apis/Restaurant';

import { SidebarContext } from '../../contexts/SidebarContext';
import { AppContext } from '../../contexts/AppContext';
import { routesConfig } from '../../routes';
import { clearStorageCheckout } from '../../hooks/StorageCheckout';
import { useCookies } from 'react-cookie';
import { cookieName, storageCart, storageUser } from '../../constants/strings';
import GetCartId from '../../hooks/GetCartId';
import { showPrice } from '../../utils';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { StoreContext } from '../../contexts/StoreContext';
import UserLogin from '../UserLogin';

const Alert = lazy(() => import('../Alert'));
const Modal = lazy(() => import('../Modal'));

function SidebarCategory({ category, store, onClickCategory = () => { }, ...props }) {
	return (
		<li className={`sidebar-store-category`} {...props} onClick={(e) => {
			e.stopPropagation();
			onClickCategory(category, store);
		}}>
			<div className="category-infos">
				<div className="category-name">
					<span>{category.name}</span>
				</div>
			</div>
		</li>
	)
}

function SidebarStore({ store, buttonIndex, index, onClickCategory = () => { }, onClickStoreSwitchHome = () => { }, ...props }) {

	return (
		<li className={`sidebar-store sidebar-store-btn-index-${buttonIndex} sidebar-store-index-${index}`} {...props}>
			<div className="store-infos">
				<div className="store-name" onClick={() => onClickStoreSwitchHome(store)} >
					<span>{store.name}</span>
				</div>
				<div className="store-arrow-down">
					<ArrowDownSvg />
				</div>
			</div>

			{!!(store.categories && store.categories.length) &&
				(
					<ul className="store-categories">
						{store.categories.map((cat, index) => (
							<SidebarCategory key={`cat_sb_${index}`} category={cat} store={store} onClickCategory={onClickCategory} />
						))}
					</ul>
				)
			}

		</li>
	)
}

function SidebarButton({ icon, label, type, stores, onClickStore = () => { }, onClickCategory = () => { }, onClickStoreSwitchHome = () => { }, description = null, ...props }) {
	if (props.profile) {
		return (
			<li className={`sidebar-layer profile-button ${props.active ? 'active' : ''}`} onClick={props.onClick}>
				<div className="layer-infos">
					<div className="container-texts">
						<div className="label-item">
							<span>{props.children}</span>
						</div>
					</div>
				</div>
			</li>
		)
	}

	const imageType = type === 'restaurant' ?
		<DeliverySvg /> : <DeliveryScheduledSvg />

	return (
		<li className={`sidebar-layer sidebar-layer-index-${props.index}`} key={label} {...props}>
			<div className="layer-infos">
				<div>
					{icon}
				</div>
				<div className="container-texts">
					<div className="label-item">
						<span>{label}</span>
					</div>
					{!!description && (
						<div className="description-item">
							{description}
						</div>
					)}
					{!!(props.index !== 0) && (
						<div className="container-type bold font-xsm">
							{imageType}
							<span>{type === 'restaurant' ? 'Delivery' : 'Entrega agendada'}</span>
						</div>
					)}
				</div>
				{!!(props.index !== 0) && (
					<div className="arrow-down-item">
						<ArrowDownSvg />
					</div>
				)}
			</div>

			{!!(stores && stores.length) &&
				(
					<ul className="stores-item">
						{stores.map((store, index) => (
							<SidebarStore
								key={`store_${index}`}
								store={store}
								index={index}
								buttonIndex={props.index}
								onClickCategory={onClickCategory}
								onClickStoreSwitchHome={onClickStoreSwitchHome}
								onClick={(e) => {
									e.stopPropagation();
									onClickStore(index, props.index, store)
								}} />
						))}
					</ul>
				)
			}

		</li>
	)
}

export default function Sidebar() {
	const { opened, setOpened, setShowLargeContent } = useContext(SidebarContext);
	const { user, setUser, setCart, cart } = useContext(AppContext);
	const { store } = useContext(StoreContext);

	const [cartInfo, setCartInfo] = useState(null);
	const [storeList, setStoreList] = useState([]);
	const [activeRoute, setActiveRoute] = useState(null);
	const [profilePage, setProfilePage] = useState('perfil');
	// eslint-disable-next-line
	const [cookies, setCookie, removeCookie] = useCookies([cookieName]);

	let history = useHistory();
	let location = useLocation();
	let cartIdHook = GetCartId();

	const modalRef = useRef();
	const alertRef = useRef();

	useEffect(() => {
		async function getInfos() {
			const { data } = await Restaurant.get(`/config/sidebar`);

			const list = [
				{
					label: 'Início',
					path: `${path}`,
					icon: <HomeIconSvg />
				}
			]

			if (data.infos) {
				data.infos.forEach(i => {
					list.push({
						...i,
						icon: i.type === 'store' ? <StoreIconSvg /> : <RestaurantIconSvg />,
						description: i.type === 'store' ? 'Refeições congeladas, petiscos, tortas, doces e muito mais' : 'Refeições preparadas na hora para você!'
					})
				})
			}

			setStoreList(list)
		}

		getInfos();
		_setMenuOpened();
		return () => { }
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let routeInfo = null;

		for (let index = 0; index < routesConfig.length; index++) {
			const route = routesConfig[index];
			const match = matchPath(location.pathname, {
				path: route.path,
				exact: route.exact
			});

			if (match) {
				routeInfo = route;
				break;
			}
		}

		setActiveRoute(routeInfo);

		if (routeInfo && routeInfo.profile) {
			setProfilePage(routeInfo.path.replace(`${path}/`, '').replace('/:id?', ''));
		}
		else {
			setProfilePage('perfil');
		}

		setShowLargeContent(routeInfo.hideSidebar)

		return () => { }
	}, [location, setShowLargeContent]);

	useEffect(() => {
		async function getCart() {
			const cartId = cartIdHook;
			if (cartId) {
				const headers = user && user.token ? { 'x-access-token': user.token } : null;
				const { data } = await Restaurant.get(`/cart/${cartId}?resume=true`, headers ? { headers } : {});
				setCartInfo(data.cart || null);
			}
		}

		getCart();

		return () => { }
	}, [cart, user, cartIdHook]);

	useEffect(() => {
		_setMenuOpened();
		return () => { }
		// eslint-disable-next-line
	}, [store, storeList])

	function _setMenuOpened() {
		let currentMenu = null;

		if (store && storeList) {
			for (let indexBtn = 0; indexBtn < storeList.length; indexBtn++) {
				const { type, stores } = storeList[indexBtn];

				if (!type) {
					continue;
				}

				for (let indexStores = 0; indexStores < stores.length; indexStores++) {
					const { id } = stores[indexStores];
					if (id === store.id) {
						currentMenu = [indexBtn, indexStores];
						break;
					}
				}

				if (currentMenu) {
					break;
				}

			}
		}

		if (currentMenu) {
			const btn = document.querySelectorAll(`.sidebar-layer:not(.sidebar-layer-index-${currentMenu[0]})`);
			const selectedButton = document.querySelector(`.sidebar-layer.sidebar-layer-index-${currentMenu[0]}`);
			const els = document.querySelectorAll(`.sidebar-store.sidebar-store-btn-index-${currentMenu[0]}:not(.sidebar-store-index-${currentMenu[1]})`)
			const selectedStoreButton = document.querySelector(`.sidebar-store.sidebar-store-btn-index-${currentMenu[0]}.sidebar-store-index-${currentMenu[1]}`);

			if (btn) {
				btn.forEach(el => el.classList.remove('opened'));
			}

			if (els) {
				els.forEach(el => el.classList.remove('opened'));
			}

			if (selectedButton) {
				selectedButton.classList.add('opened');
			}

			if (selectedStoreButton) {
				selectedStoreButton.classList.add('opened');
			}
		}
		else {
			const btn = document.querySelectorAll(`.sidebar-layer:not(.sidebar-layer-index-1)`);
			const els = document.querySelectorAll(`.sidebar-store`)

			if (btn) {
				btn.forEach(el => el.classList.remove('opened'));
			}

			if (els) {
				els.forEach(el => el.classList.remove('opened'));
			}

			const selectedBtn = document.querySelector(`.sidebar-layer.sidebar-layer-index-1`);
			if (selectedBtn) {
				selectedBtn.classList.add('opened');
			}
		}
	}

	function _onPressButton(index) {
		if (index === 0) {
			setOpened(false);
			return history.push(`${path}`);
		}
		const els = document.querySelectorAll(`.sidebar-layer:not(.sidebar-layer-index-${index})`);
		els.forEach(el => el.classList.remove('opened'));
		document.querySelector(`.sidebar-layer.sidebar-layer-index-${index}`).classList.toggle('opened');
		return;
	}

	function _onPressStore(index, buttonIndex) {
		const els = document.querySelectorAll(`.sidebar-store.sidebar-store-btn-index-${buttonIndex}:not(.sidebar-store-index-${index})`)
		els.forEach(el => el.classList.remove('opened'));
		document.querySelector(`.sidebar-store.sidebar-store-btn-index-${buttonIndex}.sidebar-store-index-${index}`).classList.toggle('opened');
		return;
	}

	function _onPressStoreSwitchHome(store) {
		document.querySelector('#wrapper-content').scrollTo({ top: 0, behavior: 'auto' });
		return history.push(`${path}/loja/${store.slug}`)
	}

	function _onPressCategory(category, store) {
		setOpened(false);
		document.querySelector('#wrapper-content').scrollTo({ top: 0, behavior: 'auto' });
		return history.push(`${path}/loja/${store.slug}?categoria=${category.slug}`)
	}

	function _onClickBackground() {
		setOpened(false);
	}

	function _logout() {
		window.localStorage.removeItem(storageUser);
		window.localStorage.removeItem(storageCart);

		clearStorageCheckout();
		removeCookie(cookieName, { path });

		setOpened(false);

		setTimeout(() => {
			setUser(null);
			setCart(null);
		}, 300);

	}

	function _setProfileActiveRoute(page) {
		setOpened(false);
		return history.push(`${path}/${page}`)
	}

	function _onClickLogin(e) {

		if (!user) {
			return modalRef.current.show();
		}

		setOpened(false);
		return history.push(`${path}/perfil`);
	}

	async function _onLogin(data) {
		if (cartIdHook) {
			await Restaurant.post(`/cart/${cartIdHook}/change-user`, { user: data.user.id });
		}

		setOpened(false);
		setUser({ token: data.token, ...data.user });

		modalRef.current.hide();

		if (!!matchPath(location.pathname, { path: `${path}/login` })) {
			setTimeout(() => {
				history.replace(`${path}/perfil`);
			}, 300);
		}

		return;
	}

	function _onLoginError(error) {
		alertRef.current.show('Algo deu errado', error || 'Não foi possível efetuar o login. Tenta novamente.');
	}

	function _onRegister() {
		setOpened(false);
		return modalRef.current.hide();
	}

	function _onClickCart(e) {
		e.stopPropagation();
		return history.push(`${path}/checkout`);
	}

	return (
		<>
			<SidebarContainer className={`${opened ? `opened` : ''} ${activeRoute && activeRoute.hideSidebar ? 'hide' : ''}`}>
				<PerfectScrollbar className={`scroll-sidebar ${(user && !user.preRegister) ? 'with-user' : ''}`}>
					<LoginContainer onClick={_onClickLogin}>
						{/* <div className="menu-profile-image">
                            <NoImageSvg />
                        </div> */}
						<div className="menu-container-texts">
							<p className="bold">{(user && !user.preRegister && user.name) ? `Olá, ${user.name.split(' ')[0]}` : 'Seja bem vindo!'}</p>
							{!!(user && !user.preRegister && activeRoute && activeRoute.profile && !(cartInfo && cartInfo.total)) && (
								<p className="font-sm" style={{ marginTop: 3 }}>Seja bem vindo!</p>
							)}
							{!((user && !user.preRegister) && user.name) && (
								<p className="font-sm bold login-text">Fazer Login</p>
							)}
							{!!(cartInfo && cartInfo.total) && (
								<div className="show-cart-info" onClick={_onClickCart}>
									<StoreIconSvg />
									<span className="font-sm">{`(${cartInfo.qtdItems}) R$ ${showPrice(cartInfo.total)}`}</span>
								</div>
							)}
						</div>
					</LoginContainer>
					<ListLinks>
						{!!(activeRoute && !activeRoute.profile) && storeList.map((props, index) => {
							return (
								<SidebarButton
									key={`button_${index}`}
									onClick={(e) => _onPressButton(index, props)}
									onClickStore={_onPressStore}
									onClickStoreSwitchHome={_onPressStoreSwitchHome}
									onClickCategory={_onPressCategory}
									index={index}
									{...props} />
							)
						})}

						{!!(activeRoute && activeRoute.profile) && (
							<>
								<SidebarButton active={profilePage === 'perfil' || profilePage === 'editar-conta'} onClick={(e) => _setProfileActiveRoute(`perfil`)} profile>
									Meus dados
                                </SidebarButton>
								<SidebarButton active={profilePage === 'pedidos'} onClick={(e) => _setProfileActiveRoute(`pedidos`)} profile>
									Meus pedidos
                                </SidebarButton>
								<SidebarButton active={profilePage === 'cartoes'} onClick={(e) => _setProfileActiveRoute(`cartoes`)} profile>
									Meus cartões
                                </SidebarButton>
								<SidebarButton active={profilePage === 'enderecos' || profilePage === 'endereco'} onClick={(e) => _setProfileActiveRoute(`enderecos`)} profile>
									Meus endereços
                                </SidebarButton>
							</>
						)}
					</ListLinks>
				</PerfectScrollbar>

				{!!(user && !user.preRegister) && (
					<LogoutContainer className="bold" onClick={_logout}>
						Sair
					</LogoutContainer>
				)}
			</SidebarContainer>
			<SidebarBackground onClick={_onClickBackground} className={opened ? `opened` : ''} />
			<Suspense fallback={<div></div>}>
				<Modal contentStyle={{ height: 'auto' }} ref={modalRef}>
					<UserLogin
						register={true}
						onRegister={_onRegister}
						onResend={_onRegister}
						onLogin={_onLogin}
						onLoginError={_onLoginError}
						component />
				</Modal>
				<Alert ref={alertRef} />
			</Suspense>
		</>
	)
}
