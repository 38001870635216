import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import React, { Suspense, lazy, useContext, useEffect, useRef, useState } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import Restaurant from '../../apis/Restaurant'
import { path } from '../../config'
import { thumb } from '../../constants/images'
import { AppContext } from '../../contexts/AppContext'
import { breakpoints, Button, Image } from '../../styles/general'
import Input from '../Input'
import OrderItem from '../OrderItem'
import { CartDetailsContainer, CartDetailsSubHeader, CartEmpty, CartEmptyScrollWrapper, CartTotal } from './styles'

import { showPrice } from '../../utils'
import { CartContext } from '../../contexts/CartContext'
import { HeaderContext } from '../../contexts/HeaderContext'
import GetCartId from '../../hooks/GetCartId'
import { storageCart, storageUser } from '../../constants/strings'

import { ReactComponent as CartSvg } from '../../assets/images/cart.svg';
import { ReactComponent as TagSvg } from '../../assets/images/tag.svg';
import { ReactComponent as LessRoundSvg } from '../../assets/images/less-round.svg';
import { ReactComponent as MoreWhiteSvg } from '../../assets/images/more-white.svg';
import ScrollWrapper from '../ScrollWrapper'
import { routesConfig } from '../../routes'
import useUserToken from '../../hooks/GetUserToken'

const Alert = lazy(() => import('../Alert'));
const CartResume = lazy(() => import('../CartResume'));

export default function CartDetails({ screen = false, cartPage = false, onChangeCart = () => { }, ...props }) {
	const { cart, user, setCart, loading } = useContext(AppContext);
	const cartContext = useContext(CartContext);
	const { setShowDetailCart } = useContext(HeaderContext);
	const [cartInfo, setCartInfo] = useState(null);
	const [suggestions, setSuggestions] = useState(null);
	const [blockButton, setBlockButton] = useState(false);
	const [showOrders, setShowOrders] = useState([]);

	const couponRef = useRef(null);
	const alertRef = useRef(null);

	let cartIdHook = GetCartId();
	let history = useHistory();
	let location = useLocation();

	useEffect(() => {
		const cartId = cartIdHook;
		window.doLog('cartId 1', cartId);
		async function _fetchCart() {
			let userToken = useUserToken();
            
            let headers = user && user.token ? { 'x-access-token': user.token } : null;
            if(!headers && userToken){
                headers = { 'x-access-token': userToken }
            }

            window.doLog('header get cart detail', headers);
			
			const { data } = await Restaurant.get(`/cart/${cartId}`, headers ? { headers } : {});

			let showOrdersArr = [];
			const items = data.cart ? data.cart.items || [] : [];

			for (let index = 0; index < items.length; index++) {
				const { orders } = items[index];
				showOrdersArr = [...showOrdersArr, ...orders];
			}

			setShowOrders(showOrdersArr);

			cartContext.setCartInfo(data.cart || null);

			if (data.cart) {
				return setCartInfo(data.cart);
			}

			return null;
		}

		async function _fetchSuggestions() {
			const { data } = await Restaurant.get(`/cart/0/suggestions`);

			if (data.suggestions) {
				return setSuggestions(data.suggestions || []);
			}

			return null;
		}

		_fetchSuggestions();

		if (cartId) {
			_fetchCart();
		}
		else {
			setCartInfo(null);
		}

		return () => { setCartInfo(null); }
		// eslint-disable-next-line
	}, [cart, user]);

	useEffect(() => {
		let routeInfo = {};

		for (let index = 0; index < routesConfig.length; index++) {
			const route = routesConfig[index];
			const match = matchPath(location.pathname, {
				path: route.path,
				exact: route.exact
			});

			if (match) {
				routeInfo = route;
				break;
			}
		}

		const rootEl = document.querySelector('#root');
		if (routeInfo.hideCart) {
			rootEl.classList.add('hide-cart');
		} else {
			rootEl.classList.remove('hide-cart');
		}

		return () => {
			rootEl.classList.remove('hide-cart');
		}
	}, [location]);

	async function _onRemoveItem(item) {
		const cartId = cartIdHook;
		window.doLog('cartId 2', cartId);
		const headers = user && user.token ? { 'x-access-token': user.token } : null;
		const { data } = await Restaurant.post(`/cart/${cartId}/remove`, { items: [item.id] }, headers ? { headers } : {})

		cartContext.setCartInfo(data.cart || null);

		if (data.cart) {
			setCart({ id: data.cart.id, time: (new Date()).getTime() });
			return setCartInfo(data.cart)
		}
	}

	async function _onChangeItem({ id, quantity }) {
		setBlockButton(true);
		const cartId = cartIdHook;
		window.doLog('cartId 3', cartId);
		const headers = user && user.token ? { 'x-access-token': user.token } : null;

		if (!quantity) {
			await _onRemoveItem({ id });
			setBlockButton(false);
			return;
		}

		const { data } = await Restaurant.post(`/cart/${cartId}/quantity`, { id, quantity }, headers ? { headers } : {});
		setBlockButton(false);

		cartContext.setCartInfo(data.cart || null);

		if (data.cart) {
			return setCart({ id: data.cart.id, time: (new Date()).getTime() });
		}

		return;
	}

	async function _onSendCoupon() {
		if (blockButton) { return }
		const cartId = cartIdHook;
		window.doLog('cartId 4', cartId);
		const coupon = couponRef.current.value;
		const headers = user && user.token ? { 'x-access-token': user.token } : null;
		setBlockButton(true);
		const { data } = await Restaurant.post(`/cart/${cartId}/coupon`, { coupon, apply: true }, headers ? { headers } : {});
		setBlockButton(false);


		if (data.cart) {
			cartContext.setCartInfo(data.cart);
			setCart({ id: cartId, time: (new Date()).getTime() })
			return setCartInfo(data.cart);
		}

		if (data.error) {
			couponRef.current.value = '';
			return alertRef.current.show('Algo deu errado', `${data.error}`);
		}

		return;
	}

	async function _removeCoupon() {
		const cartId = cartIdHook;
		window.doLog('cartId 5', cartId);
		if (cartId) {
			setBlockButton(true);
			const headers = user && user.token ? { 'x-access-token': user.token } : null;
			const { data } = await Restaurant.post(`/cart/${cartId}/remove-coupon`, null, headers ? { headers } : {});
			setBlockButton(false);
			setCart({ id: cartId, time: (new Date()).getTime() })

			if (data.cart) {
				cartContext.setCartInfo(data.cart);
				return setCartInfo(data.cart);
			}

			if (data.error) {
				return alertRef.current.show('Algo deu errado', `${data.error}`);
			}
		}
	}

	async function _addSuggestionsToCart(product) {
		setBlockButton(true);

		let cartId = cartIdHook;
		let correctUser = user;

		window.doLog('LOG ~ file: index.js ~ line 185 ~ cartId ANTES', cartId);
		window.doLog('LOG ~ file: index.js ~ line 186 ~ correctUser ANTES', correctUser);

		//! Fix BUG: Perde referencia de user e carrinho
		if (!correctUser) {
			const strUserStorage = window.localStorage.getItem(storageUser);
			window.doLog('LOG ~ file: index.js ~ line 188 ~ strUserStorage', strUserStorage);
			correctUser = JSON.parse(strUserStorage);
		}

		if (!cartId) {
			const correctCart = JSON.parse(window.localStorage.getItem(storageCart));
			window.doLog('LOG ~ file: index.js ~ line 194 ~ correctCart', correctCart);
			cartId = correctCart ? correctCart.id || null : null;
		}

		window.doLog('LOG ~ file: index.js ~ line 201 ~ cartId DEPOIS', cartId);
		window.doLog('LOG ~ file: index.js ~ line 202 ~ correctUser DEPOIS', correctUser);

		const headers = correctUser && correctUser.token ? { 'x-access-token': correctUser.token } : null;
		const url = `/cart${cartId ? `/${cartId}` : ''}/add`;
		const variant = {
			id: product.grid[0].variants[0].id,
			quantity: 1,
			sideDishes: {
				free: [],
				paid: []
			}
		}
		const post = {
			note: '',
			items: [{
				variants: [variant],
				store: product.store.id,
			}]
		}
		const { data } = await Restaurant.post(url, post, headers ? { headers } : {})
		setBlockButton(false);

		cartContext.setCartInfo(data.cart || null);

		if (data.error) {
			if (data.error === 'O carrinho já está fechado') {
				setCart(null);
				return alertRef.current.show('Algo deu errado', data.error);
			}
		}

		if (data.cart) {
			setTimeout(() => {
				document.querySelector('#scroll-cart-details').scrollTo({ top: 0, behavior: "smooth" });
				if (screen) {
					document.querySelector('#wrapper-content').scrollTo({ top: 0, behavior: "smooth" });
				}
			}, 300);

			setCart({ id: data.cart.id, time: (new Date()).getTime() });

			return setCartInfo(data.cart)
		}
	}

	async function _onClickCheckout(evt) {
		if (blockButton || loading) { return; }

		if (matchPath(location.pathname, { path: `${path}/checkout` })) {
			const elBtnCheckout = document.querySelector('#btn-checkout');
			if (elBtnCheckout) {
				return elBtnCheckout.click();
			}
		}

		if (matchPath(location.pathname, { path: `${path}/pagamento` })) {
			const elBtnCheckout = document.querySelector('#btn-payment');
			if (elBtnCheckout) {
				return elBtnCheckout.click();
			}
		}

		if (matchPath(location.pathname, { path: `${path}/pagamento` })) {
			const elBtnCheckout = document.querySelector('#btn-checkout-finish');
			if (elBtnCheckout) {
				return elBtnCheckout.click();
			}
		}


		evt.stopPropagation();
		setShowDetailCart(false);
		const cartId = cartIdHook;
		window.doLog('cartId 6', cartId);
		return history.push(`${path}/checkout?cart=${cartId}`);
	}

	const hasItems = !!(showOrders.length > 0);
	const couKey = cartInfo && cartInfo.coupon && cartInfo.coupon.key ? cartInfo.coupon.key : '';
	const couDiscount = cartInfo ? cartInfo.discount || 0 : 0;
	const couponInfo = cartInfo && cartInfo.coupon ? cartInfo.coupon : null;

	if (!hasItems) {
		return (
			<CartDetailsContainer className={`${screen ? 'screen' : 'modal'} ${props.className || ''}`}>
				<CartEmptyScrollWrapper id="scroll-cart-details">
					<CartDetailsSubHeader className="filter-bar subheader-height">
						<span>Carrinho vazio</span>
						<div>
							<CartSvg />
						</div>
					</CartDetailsSubHeader>
					{!cartPage && (
						<CartEmpty>
							<span>Carrinho vazio, adicione ítens</span>
						</CartEmpty>
					)}
				</CartEmptyScrollWrapper>
			</CartDetailsContainer>
		)
	}

	return (
		<CartDetailsContainer className={`${screen ? 'screen' : 'modal'} ${props.className || ''}`}>
			<ScrollWrapper id="scroll-cart-details">
				<div className="session-header detail__cart__header subheader-height">
					<span className="font-md">Resumo do pedido</span>
				</div>

				<div className="detail__cart__padding__content">
					<div className="detail__cart__orders">
						<ul>
							{showOrders.map(order => (
								order.items.map(item => (
									<li key={`order_item_${item.id}`}>
										<OrderItem
											onChangeItem={_onChangeItem}
											onClickRemove={_onRemoveItem}
											item={item} />
									</li>
								))
							))}
						</ul>
					</div>

					<div className="detail__cart__coupon">
						<div className="header-coupon">
							<TagSvg />
							<span className="bold">Cupom de desconto</span>
						</div>

						{!couponInfo && (
							<div>
								<Input value={couKey} ref={couponRef} type="text" placeholder="Insira seu cupom aqui" />
								<Button style={{ opacity: blockButton ? .5 : 1 }} onClick={_onSendCoupon}>Aplicar</Button>
							</div>
						)}

						{!!couponInfo && (
							<div className="remove__coupon font-sm">
								<span>{couponInfo.key}</span>
								<div className="btn-remove" onClick={_removeCoupon}>
									- R$ {showPrice(couDiscount)}
									<div style={{ marginLeft: '8px', width: '20px', height: '20px' }}>
										<LessRoundSvg />
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="detail__cart__suggestions">
					{!!(suggestions && suggestions.length) && (
						<>
							<div className="suggestions__header subheader-height">
								<span className="font-md">Peça também</span>
							</div>

							<div className="container__carousel__suggestions">
								<CarouselProvider
									isPlaying={true}
									playDirection={'forward'}
									interval={10000}
									visibleSlides={window.innerWidth < breakpoints.md ? 2.1 : 3.2}
									naturalSlideWidth={125}
									naturalSlideHeight={175}
									totalSlides={suggestions.length}>
									<Slider style={{ paddingBottom: '5px' }}>
										{suggestions.map((product, index) => (
											<Slide key={`slide_${index}`} index={index}>
												<div className="container__infos__carousel__suggestions">
													<Image src={product.images[0] || thumb} alt="image_pro" />
													<div className="product-info">
														<div className="container__infos__carousel__product-name radley">{product.name}</div>
														<div className="price-from">A partir de</div>
														<div className="container__infos__carousel__product-price">
															<span className="font-xsm">R$</span>{showPrice(product.grid[0].variants[0].price || 0)}
														</div>
														<div className="sug-fake-button" onClick={() => _addSuggestionsToCart(product)}>
															<MoreWhiteSvg />
														</div>
													</div>
												</div>
											</Slide>
										))}
									</Slider>
								</CarouselProvider>
							</div>

						</>
					)}
				</div>
			</ScrollWrapper>

			{hasItems && (
				<CartTotal className="subheader-height">
					<div className="total-container">
						<p className="bold">Total</p>
						<p style={{ marginTop: '4px' }} className="">{cartInfo ? `R$ ${showPrice(cartInfo.total || 0)}` : 'carregando'}</p>
					</div>
					<Button style={{ opacity: blockButton || loading ? .5 : 1 }} onClick={_onClickCheckout}>Finalizar</Button>
				</CartTotal>
			)}

			<Suspense fallback={<div></div>}>
				<CartResume hide={true} />
				<Alert ref={alertRef} />
			</Suspense>
		</CartDetailsContainer>
	)
}
