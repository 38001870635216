import React, { useRef, useState } from 'react';
import { breakpoints } from "../../styles/general"
import PerfectScrollbar from 'react-perfect-scrollbar';

const ScrollWrapper = ({ content = false, ...props }) => {
    const [reachEnd, setReachEnd] = useState(false);
    const containerRef = useRef();

    function _onReachEnd() {
        if (content && containerRef.current) {
            setReachEnd(true);
        }
    }

    function _onScrollUp() {
        if (content && containerRef.current && reachEnd) {
            setReachEnd(false);
            document.querySelector('#content-scroll-control').scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    if (window.innerWidth < breakpoints.md) {
        return (
            <div {...props}>
                {props.children}
            </div>
        )
    }

    return (
        <PerfectScrollbar
            ref={containerRef}
            onScrollUp={_onScrollUp}
            onYReachEnd={_onReachEnd}
            {...props}>
            {props.children}
        </PerfectScrollbar>
    )
}

export default ScrollWrapper