import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { path } from './config';
import { storageUser } from './constants/strings';

const About = lazy(() => import('./pages/About'));
const AddCard = lazy(() => import('./pages/AddCard'));
const Address = lazy(() => import('./pages/Address'));
const Addresses = lazy(() => import('./pages/Addresses'));
const Cards = lazy(() => import('./pages/Cards'));
const Cart = lazy(() => import('./pages/Cart'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Confirmation = lazy(() => import('./pages/Confirmation'));
const EditAccount = lazy(() => import('./pages/EditAccount'));
const Home = lazy(() => import('./pages/Home'));
const Orders = lazy(() => import('./pages/Orders'));
const Payment = lazy(() => import('./pages/Payment'));
const Product = lazy(() => import('./pages/Product'));
const Profile = lazy(() => import('./pages/Profile'));
const Register = lazy(() => import('./pages/Register'));
const Login = lazy(() => import('./pages/Login'));
const ResendPass = lazy(() => import('./pages/ResendPass'));
const Store = lazy(() => import('./pages/Store'));
const Search = lazy(() => import('./pages/Search'));
const NoMatch = lazy(() => import('./pages/NoMatch'));

export const routesConfig = [
    // HOME
    {
        useDefaultTheme: true,
        component: <Home />,
        path: `${path}/`,
        label: 'Home',
        exact: true,
        private: false,
        redirect: null,
        leftIcon: 'menu',
        blockLogoClick: true,
        showSearchInput: true
    },
    // BUSCA
    {
        useDefaultTheme: true,
        component: <Search />,
        path: `${path}/busca`,
        label: 'Busca',
        exact: true,
        private: false,
        redirect: null,
        leftIcon: 'menu',
        blockLogoClick: true,
        showSearchInput: true
    },
    // SAIBA MAIS
    {
        useDefaultTheme: false,
        component: <About />,
        path: `${path}/saiba-mais/:id`,
        label: 'Saiba Mais',
        exact: true,
        private: false,
        redirect: null,
        leftIcon: 'back-button',
        hideMenuButton: true,
        hideMenu: true,
        hideCart: true,
        showSearchInput: true,
        
    },
    // LOJA
    {
        useDefaultTheme: false,
        component: <Store />,
        path: `${path}/loja/:id`,
        label: 'Loja',
        exact: true,
        private: false,
        redirect: null,
        leftIcon: 'menu',
        blockLogoClick: 'scroll-top',
        showSearchInput: true
    },
    // PRODUTO
    {
        useDefaultTheme: false,
        component: <Product />,
        path: `${path}/produto/:id`,
        label: 'Produto',
        exact: false,
        private: false,
        redirect: null,
        leftIcon: 'menu',
        hideFooter: true
    },
    // CARRINHO
    {
        useDefaultTheme: true,
        component: <Cart />,
        path: `${path}/carrinho`,
        label: 'Carrinho',
        exact: true,
        private: false,
        redirect: null,
        leftIcon: 'back-button',
        hideMenuButton: true,
        blockLogoClick: true,
        hideFooter: true
    },
    // CHECKOUT
    {
        useDefaultTheme: true,
        component: <Checkout />,
        path: `${path}/checkout`,
        label: 'Checkout',
        exact: true,
        private: false,
        redirect: null,
        leftIcon: 'back-button',
        blockLogoClick: true,
        hideMenuButton: true,
        hideSidebar: true,
        hideFooter: true
    },
    // PAGAMENTO
    {
        useDefaultTheme: true,
        component: <Payment />,
        path: `${path}/pagamento`,
        label: 'Pagamento',
        exact: true,
        private: false,
        payment: true,
        hideCart: false,
        redirect: `${path}/checkout`,
        leftIcon: 'back-button',
        blockLogoClick: true,
        hideMenuButton: true,
        hideSidebar: true,
        hideFooter: true
    },
    // CONFIRMAÇÃO
    {
        useDefaultTheme: true,
        component: <Confirmation />,
        path: `${path}/confirmacao`,
        label: 'Confirmação',
        exact: true,
        private: false,
        redirect: null,
        leftIcon: 'home-button',
        hideCart: false,
        hideMenuButton: true,
        hideSidebar: true
    },
    // LOGIN
    {
        useDefaultTheme: true,
        component: <Login />,
        path: `${path}/login`,
        label: 'Login',
        exact: true,
        private: false,
        redirect: null,
        leftIcon: 'back-button',
        hideMenuButton: true,
        hideCart: true,
        hideSidebar: false
    },
    // REENVIO DE SENHA
    {
        useDefaultTheme: true,
        component: <ResendPass />,
        path: `${path}/senha`,
        label: 'Reenvio de senha',
        exact: true,
        private: false,
        redirect: null,
        leftIcon: 'back-button',
        hideMenuButton: true,
        hideCart: true,
        hideSidebar: false
    },
    // CADASTRO
    {
        useDefaultTheme: true,
        component: <Register />,
        path: `${path}/cadastro`,
        label: 'Cadastro',
        exact: true,
        private: false,
        redirect: null,
        leftIcon: 'back-button',
        hideMenuButton: true,
        hideCart: true,
        hideSidebar: false
    },
    // PERFIL
    {
        useDefaultTheme: true,
        component: <Profile />,
        path: `${path}/perfil`,
        label: 'Profile',
        exact: true,
        private: true,
        redirect: `${path}/login`,
        leftIcon: 'back-button',
        profile: true,
        hideMenuButton: true,
        hideCart: true,
        hideSidebar: false
    },
    // EDITAR CONTA
    {
        useDefaultTheme: true,
        component: <EditAccount />,
        path: `${path}/editar-conta`,
        label: 'Editar Conta',
        exact: true,
        private: true,
        redirect: `${path}/login`,
        leftIcon: 'back-button',
        profile: true,
        hideMenuButton: true,
        hideCart: true,
    },
    // MEUS PEDIDOS
    {
        useDefaultTheme: true,
        component: <Orders />,
        path: `${path}/pedidos`,
        label: 'Pedidos',
        exact: true,
        private: true,
        redirect: `${path}/login`,
        leftIcon: 'back-button',
        profile: true,
        hideMenuButton: true,
        hideCart: true,
    },
    // MEUS CARTÕES
    {
        useDefaultTheme: true,
        component: <Cards />,
        path: `${path}/cartoes`,
        label: 'Cartões',
        exact: true,
        private: true,
        redirect: `${path}/login`,
        leftIcon: 'back-button',
        profile: true,
        hideMenuButton: true,
        hideCart: true,
    },
    // ADD CARTÃO
    {
        useDefaultTheme: true,
        component: <AddCard />,
        path: `${path}/add-card`,
        label: 'Adicionar Cartão',
        exact: true,
        private: true,
        redirect: `${path}/login`,
        leftIcon: 'back-button',
        profile: true,
        hideMenuButton: true,
        hideCart: true,
    },
    // MEUS ENDEREÇOS
    {
        useDefaultTheme: true,
        component: <Addresses />,
        path: `${path}/enderecos`,
        label: 'Endereços',
        exact: true,
        private: true,
        redirect: `${path}/login`,
        leftIcon: 'back-button',
        profile: true,
        hideMenuButton: true,
        hideCart: true,
    },
    // ADD/EDITAR ENDEREÇO
    {
        useDefaultTheme: true,
        component: <Address />,
        path: `${path}/endereco/:id?`,
        label: 'Endereço',
        exact: true,
        private: true,
        redirect: `${path}/login`,
        leftIcon: 'back-button',
        profile: true,
        hideMenuButton: true,
        hideCart: true,
    },
    // SEM ROTA
    {
        useDefaultTheme: true,
        component: <NoMatch />,
        path: '*',
        label: '404',
        exact: false,
        private: false,
        redirect: null,
        leftIcon: 'back-button'
    },
];

const PrivateRoute = ({ redirect = null, children, ...rest }) => {
    let user = null;
    const strUserStorage = window.localStorage.getItem(storageUser);
    if (strUserStorage) {
        user = JSON.parse(strUserStorage);
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                user && !user.preRegister ? (children) :
                    (
                        <Redirect
                            to={{
                                pathname: redirect || path,
                                state: { from: location },
                            }}
                        />
                    )
            }
        />
    );
};

const PaymentPrivateRoute = ({ redirect = null, children, ...rest }) => {
    const dataStorageCheckout = true;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                dataStorageCheckout ? (children) :
                    (
                        <Redirect
                            to={{
                                pathname: redirect || path,
                                state: { from: location },
                            }}
                        />
                    )
            }
        />
    );
};

const Routes = () => (
    <Suspense fallback={<div style={{ height: '100vh', width: '100%' }}></div>}>
        <Switch>
            {routesConfig.map((r) => {
                if (r.private) {
                    return (
                        <PrivateRoute key={`route_${r.label}`} redirect={r.redirect} exact={r.exact} path={r.path}>
                            {r.component}
                        </PrivateRoute>
                    );
                }
                else if (r.payment) {
                    return (
                        <PaymentPrivateRoute key={`route_${r.label}`} redirect={r.redirect} exact={r.exact} path={r.path}>
                            {r.component}
                        </PaymentPrivateRoute>
                    );
                }

                return (
                    <Route key={`route_${r.label}`} exact={r.exact} path={r.path}>
                        {r.component}
                    </Route>
                );
            })}
        </Switch>
    </Suspense>
);

export default Routes;
