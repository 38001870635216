import styled, { keyframes } from 'styled-components';
import { BLUE } from '../../constants/colors';
import { breakpoints } from '../../styles/general';

export const FilterContainer = styled.div`
    display: flex;
    position: relative;
    z-index: 3;
    padding: 0!important;

    .divisor {
        width: 100%;
        height: 1px;
        background-color:  #ECEFF1;

        &.mobile {
            @media screen and (min-width: ${breakpoints.md}px){
                display: none;
            }
        }
    }

    .filter-select-container {
        position: relative;

        padding: 0 0 0 16px;
        padding-right: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        .filter-select-type {
            width: 205px;
            background: white;
            height: 32px;
            margin-right: 10px;
            
            border-radius: 15px;
            border: 1px solid #E0E0E0;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 15px;
            cursor: pointer;

            font-weight: 400;

            @media screen and (max-width: ${breakpoints.md}px){
                min-width: 0;
                width: auto;

                span {
                    margin-right: 10px;
                }
            }
        }

        .filter-select-options {
            display: none;
            position: fixed;
            left: 0;
            right: 0;
            width: 100%;
            top: initial;
            bottom: 0;
            border-top: 1px solid #cecece;

            background: white;
            border: 1px solid #E0E0E0;
            border-radius: 15px;

            z-index: 2;

            .filter-title-modal{
                font-weight: bold;
                font-size: 14px;
                display: block;
                margin: 0 auto 16px;
                text-align: center;
            }

            .filter-title-line {
                width: 40px;
                height: 4px;
                background: #CFD8DC;
                border-radius: 6px;
                margin: 16px auto;
            }

            @media screen and (max-width: ${breakpoints.md}px){
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                padding-bottom: 20px;
            }

            @media screen and (min-width: ${breakpoints.md}px){
                position: absolute;
                top: calc(100% - 18px);
                bottom: initial;
                left: 16px;
                width: calc(100% - 26px);
                border-top: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;

                .filter-title-modal, .filter-title-line{
                    display: none;
                }
            }
        }

        .filter-select-options-background {
            display: none;
            width: 100vw;
            height: 100vh;
            background: rgba(0,0,0,.4);
            left: 0;
            top: 0;
            position: fixed;
            z-index: 1;
        }
        

        &.opened {
            @media screen and (min-width: ${breakpoints.md}px){
                .filter-select-type {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border-bottom: none;
                }
            }
            
            @media screen and (max-width: ${breakpoints.md}px){
                .filter-select-options-background {
                    display: block
                }
            }

            .filter-select-options { display: block; }
        }
    }   

    .filter-store-list {
        display: flex;
        flex: 1;
        overflow: visible;

        #filter-selected-border {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 4px;
            background-color: #CE262C;
            transition: all .3s;
        }
        
        ul {
            overflow: auto hidden;
            /* height: 32px; */
            height: 100%;
            position: relative;
            padding-left: 0;

            ::-webkit-scrollbar {
                display: none;
            }

            li {
                flex: 0 0;
                height: 100%;
                text-align: center;
                white-space: nowrap;
                margin-left: 5px;
                padding: 0 15px;
                border-bottom: 4px solid transparent;
                text-transform: uppercase;
                color: rgba(0,0,0,1);
                cursor: pointer;
                font-weight: 400;
                transition: border .3s ease-in-out, color .3s ease-in-out;

                div {
                    position: relative;
                    top: 4px;
                }

                &.active {
                    color: #CE262C;
                    border-bottom: 4px solid #CE262C;
                    font-weight: 700;
                }
            }
        }

    }
`;

const layerSubItemsAnimation = keyframes`
    0%{
        opacity: 0;
        -webkit-opacity: 0;
    }
    100% {
        opacity: 1;
        -webkit-opacity: 1;
    }
`;

export const SelectOption = styled.li`
    cursor: pointer;
    padding: 16px 15px;
    animation: ${layerSubItemsAnimation} .5s ease-in-out;

    .layer-infos {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .check-container {
            margin-right: 15px;
            width: 24px;
            height: 24px;
            background: #ECEFF1;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            & > div {
                width: 14px;
                height: 14px;
                background: ${props => props.selected ? `${BLUE.dark}`: 'transparent'};
                border-radius: 14px;
            }

            @media screen and (min-width: ${breakpoints.md}px){
                display: none;
            }
        }
    }

    .container-texts {
        margin-left: 10px;
        flex: 1;
        
        .label-item {
            font-weight: 700;
            text-transform: uppercase;
        }

        .description-item {
            font-size: 10px;
            line-height: 11px;
            color: #78909C;
            margin-top: 2px;
            font-weight: 400;
        }
        
        .container-type {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span { margin-left: 5px; text-transform: uppercase; }
        }
    }

    &.opened {
        border-bottom: none;
    }
`