import axios from 'axios';
import { apiKey, endpointApi, secretRequest, stage } from '../config';
import Crypto from 'crypto-js';

const Restaurant = axios.create({
    baseURL: endpointApi,
    headers: {
        'x-api-key': apiKey,
        'X-Encrypted': 'encrypted'
    },
    validateStatus: false
});

Restaurant.interceptors.request.use(config => {
    const currentConfig = config;
    const configData = config.data || null;

    if (configData) {
        const encryptedData = Crypto.AES.encrypt(JSON.stringify(configData), secretRequest).toString();
        currentConfig.data = { encrypted: encryptedData };
        
        if (stage !== 'prod') {
            currentConfig.data = { ...currentConfig.data, ...configData }
        }
    }

    return currentConfig;
})

export default Restaurant;
