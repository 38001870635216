import React, { useEffect, useState } from 'react'
import less from '../../assets/images/less.svg'
import more from '../../assets/images/more.svg'
import { thumb } from '../../constants/images'
import { Image } from '../../styles/general'
import { showPrice } from '../../utils'
import { ImageSkeleton, TextSkeleton } from '../Skeleton'
import { OrderItemContainer, OrderItemImageContainer, OrderItemInfosContainer, OrderItemQuantityContainer } from './styles'

export default function OrderItem({ item = {}, onChangeItem, onClickRemove, loading }) {
    const [quantity, setQuantity] = useState(item.quantity || 0);

    useEffect(() => {
        setQuantity(item.quantity);
        return () => { setQuantity(item.quantity || 0) }
    }, [item]);

    function _onSetQuantity(type) {
        let qtd = quantity;

        if (type === 'add') {
            qtd += 1;
        }
        else {
            qtd -= 1;
        }

        setQuantity(qtd);
        return onChangeItem({ ...item, quantity: qtd });
    }

    if (loading) {
        return (
            <OrderItemContainer>
                <OrderItemImageContainer className="order__item__container__image">
                    <ImageSkeleton style={{ height: '110px' }} />
                </OrderItemImageContainer>
                <OrderItemInfosContainer className="order__item__container__infos">
                    <TextSkeleton style={{ marginBottom: '5px', maxWidth: '60%' }} />
                    <TextSkeleton style={{ marginBottom: '5px', maxWidth: '60%' }} />
                    <TextSkeleton style={{ marginBottom: '5px', maxWidth: '60%' }} />
                </OrderItemInfosContainer>
            </OrderItemContainer>
        )
    }

    return (
        <OrderItemContainer>
            <OrderItemImageContainer className="order__item__container__image">
                <Image src={item.product.images[0] || thumb} alt={item.product.name} />
                <div className="font-sm" onClick={() => onClickRemove(item)}>Excluir</div>
            </OrderItemImageContainer>
            <OrderItemInfosContainer className="order__item__container__infos">
                <div className="container-name-price">
                    <div className="item-name radley font-md">{item.product.name}</div>
                    <div className="order__item__price bold">R${showPrice(item.variant.price)}</div>
                </div>

                <div className="container-variant-name">
                    <div className="variant-name font-sm">
                        {item.variant.name}
                    </div>
                    {!!item.variant.label && (
                        <span className="font-sm">{item.variant.label}</span>
                    )}
                </div>

                <div className="container-sd-qtd">
                    <ul className="font-sm order__item__side__dish_list">
                        {!!(item.sideDishes && item.sideDishes.length) && item.sideDishes.map(sideDish => (
                            <li key={`side_dish_${sideDish.id}`}>
                                <div className="sd-qtd">
                                    {`${sideDish.quantity || 1}`}
                                </div>
                                <span>
                                    {sideDish.name} {sideDish.price ? `(R$${showPrice(sideDish.price)})` : ''}
                                </span>
                            </li>
                        ))}

                        {item.notes && (
                            <li>
                                <div className="sd-qtd">obs</div>
                                <span>
                                    {item.notes}
                                </span>
                            </li>
                        )}
                    </ul>

                    <OrderItemQuantityContainer className="order__item__container__qtd">
                        <div className="btn-qtd less" onClick={() => _onSetQuantity('remove')}>
                            <Image src={less} alt="less" />
                        </div>
                        <div className="font-sm bold">{quantity}</div>
                        <div className="btn-qtd" onClick={() => _onSetQuantity('add')}>
                            <Image src={more} alt="more" />
                        </div>
                    </OrderItemQuantityContainer>
                </div>
            </OrderItemInfosContainer>
        </OrderItemContainer>
    )
}
