import React, { useContext, useEffect, useRef, useState } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { path } from '../../config';
import { StoreContext } from '../../contexts/StoreContext';
import { routesConfig } from '../../routes';
import { FilterContainer, SelectOption } from './styles'

import { ReactComponent as StoreIconSvg } from '../../assets/images/store-icon.svg';
import { ReactComponent as RestaurantIconSvg } from '../../assets/images/restaurant-icon.svg';

import { ReactComponent as DeliverySvg } from '../../assets/images/delivery.svg';
import { ReactComponent as ArrowDownBlack } from '../../assets/images/arrow-down-black.svg';
import { ReactComponent as DeliveryScheduledSvg } from '../../assets/images/delivery-scheduled.svg';
import { AppContext } from '../../contexts/AppContext';

export function SelectButton({ icon, label, type, description = null, ...props }) {
    const imageType = type === 'restaurant' ?
        <DeliverySvg /> : <DeliveryScheduledSvg />
    return (
        <SelectOption className={`select-option select-option-index-${props.index}`} key={label} {...props}>
            <div className="layer-infos">
                <div className="check-container">
                    <div></div>
                </div>
                <div>
                    {icon}
                </div>
                <div className="container-texts">
                    <div className="label-item">
                        <span>{label}</span>
                    </div>
                    {!!description && (
                        <div className="description-item">
                            {description}
                        </div>
                    )}
                    {!!(props.index !== 0) && (
                        <div className="container-type bold font-xsm">
                            {imageType}
                            <span>{type === 'restaurant' ? 'Delivery' : 'Entrega agendada'}</span>
                        </div>
                    )}
                </div>
            </div>
        </SelectOption>
    )
}

export default function StoreFilter() {
    const { loading } = useContext(AppContext);
    const { store, storeList, filterType, setFilterType } = useContext(StoreContext);

    const [widthScrollbar, setWidthScrollbar] = useState(null);
    const [visible, setVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [openedSelectOptions, setOpenedSelectOptions] = useState(false);

    const divScrollbarRef = useRef(null);
    const divBorderSelected = useRef(null);
    const ulScrollRef = useRef(null);

    let history = useHistory();
    let location = useLocation();

    useEffect(() => {
        let routeInfo = null;

        for (let index = 0; index < routesConfig.length; index++) {
            const route = routesConfig[index];
            const matchStore = matchPath(location.pathname, { path: `${path}/loja` });
            const matchSearch = matchPath(location.pathname, { path: `${path}/busca` });
            const matchHome = matchPath(location.pathname, { path: `${path}`, exact: true });

            if (matchStore || matchHome || matchSearch) {
                routeInfo = route;
                break;
            }
        }

        const showFilter = !!routeInfo

        setVisible(showFilter);
        setOpenedSelectOptions(false);

        return () => { }
    }, [location])

    useEffect(() => {
        function _onResize() {
            if (divScrollbarRef.current) {
                setWidthScrollbar(divScrollbarRef.current.offsetWidth);
            }
        }

        if (divScrollbarRef.current) {
            setWidthScrollbar(divScrollbarRef.current.offsetWidth);
        }

        if (!storeList) {
            window.addEventListener('resize', _onResize);
        }

        return () => {
            setWidthScrollbar(null);
            window.removeEventListener('resize', _onResize);
        }
    }, [visible, storeList])

    useEffect(() => {
        if (store) {
            setSelectedId(store.id);
            setFilterType(store.type);

            setTimeout(() => {
                const elSto = document.querySelector(`.store-item-list-${store.id}`);
                if (elSto) {
                    ulScrollRef.current.scrollTo({ left: elSto.offsetLeft, behavior: 'smooth' })
                }
            }, 300);
        }
        else {
            setSelectedId(null);
        }

        return () => { }
        // eslint-disable-next-line
    }, [store])

    useEffect(() => {
        const elResume = document.querySelector('#cart_resume_container');

        if (elResume) {
            if (openedSelectOptions) {
                elResume.classList.add('fix-mobile-index')
            }
            else {
                elResume.classList.remove('fix-mobile-index')
            }
        }

        return () => {
            if (elResume) { elResume.classList.remove('fix-mobile-index') }
        }
    }, [openedSelectOptions])

    async function _onStorePress(store) {
        if (loading) { return; }
        setOpenedSelectOptions(false);
        setSelectedId(store.id);
        return history.push(`${path}/loja/${store.slug || store.id}`);
    }

    function _onSelectFilterType(filter) {
        setFilterType(filter);

        if (!matchPath(location.pathname, { path: `${path}`, exact: true })) {
            history.push(`${path}`);
        }
    }

    if (!visible) { return null; }

    return (
        <FilterContainer className="filter-bar subheader-height">
            <div className={`filter-select-container ${openedSelectOptions ? 'opened' : ''}`} onClick={() => setOpenedSelectOptions(!openedSelectOptions)}>
                <div className="filter-select-type">
                    <span>{filterType === 'store' ? 'Loja' : 'Restaurante'}</span>
                    <ArrowDownBlack />
                </div>
                <div className="filter-select-options-background"></div>
                <div className="filter-select-options">
                    <div className="filter-title-line"></div>
                    <span className="filter-title-modal">Selecione uma opção</span>
                    <ul>
                        <SelectButton
                            selected={filterType === 'store'}
                            onClick={() => _onSelectFilterType('store')}
                            icon={<StoreIconSvg />}
                            type={'store'}
                            label="Loja"
                            description={'Refeições congeladas, petiscos, tortas, doces e muito mais'}
                        />
                        <li className="divisor"></li>
                        <SelectButton
                            selected={filterType === 'restaurant'}
                            onClick={() => _onSelectFilterType('restaurant')}
                            icon={<RestaurantIconSvg />}
                            type={'restaurant'}
                            label="Restaurante"
                            description={'Refeições preparadas na hora para você!'}
                        />
                        <li className="divisor mobile"></li>
                    </ul>
                </div>
            </div>
            <div ref={divScrollbarRef} className="filter-store-list">
                {!!(widthScrollbar && storeList) && (
                    <ul ref={ulScrollRef} className="filter-store-list" style={{ width: widthScrollbar }}>
                        {storeList.filter(sto => sto.type === filterType).map((sto, index) => (
                            <li
                                key={`filter-store-${index}`}
                                onClick={(e) => { e.stopPropagation(); return _onStorePress(sto); }}
                                className={`store-item-list-${sto.id} flex-center font-sm bold ${selectedId && sto.id === selectedId ? 'active' : ''}`}>
                                <div>
                                    {sto.name}
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
                <div ref={divBorderSelected} id="filter-selected-border"></div>
            </div>
        </FilterContainer>
    )
}
