import styled from "styled-components";
import { black, BLUE, FONTS, GREY } from "../../constants/colors";
import { breakpoints } from "../../styles/general";

export const OrderItemContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const OrderItemImageContainer = styled.div`
    width: 65px;
    height: 65px;
    border-radius: 5px;
    overflow: hidden;

    & > div {
        color: red;
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const OrderItemInfosContainer = styled.div`
    margin-left: 8px;
    padding-right: 3px;
    flex: 1 1;

    .container-name-price {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 5px;

        .item-name {
            color: ${BLUE.dark};
        }

        .order__item__price {
            margin-left: 5px;
        }
    }

    .container-variant-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 3px;
        font-size: 10px;

        color: ${black};

        span {
            margin-left: 5px;

            font-size: 12px;
            line-height: 14px;
            @media screen and (max-width: ${breakpoints.lg}px){
                font-size: 10px;
                line-height: 12px;
            }
        }
        
        .variant-name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 2px 6px;
            background: ${FONTS.main};
            border-radius: 20px;
            color: white;
        }
    }



    .container-sd-qtd {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    ul {
        li {
            margin-bottom: 3px;
            color: ${GREY.strong};

            .sd-qtd {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px 6px;
                background: ${GREY.strong};
                border-radius: 20px;
                color: white;
                display: inline-block;
                margin-right: 5px;
            }

            span {
                font-size: 12px;
                line-height: 14px;
                @media screen and (max-width: ${breakpoints.lg}px){
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }
    }
`;

export const OrderItemQuantityContainer = styled.div`
    width: 100px;
    height: 40px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;

    border: 1px solid ${GREY.light};
    border-radius: 4px;

    .btn-qtd {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        cursor: pointer;

        img {
            width: 12px;
            height: 12px; 
        }

        &.less {
            height: 2px;
            img {
                height: 2px; 
            }
        }
    }
`;