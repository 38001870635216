import { storageCheckoutInfos } from '../constants/strings'

const INITIAL_STORAGE = {
    name: '',
    phone: '',
    email: '',
    selectedOption: null,
    addressFields: {
        valid: false,
        errors: [{ showAS: 'CEP' }],
        form: {
            postalCode: '',
            address: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: '',
        }
    }
}

export function getStorageCheckout() {
    const storageString = window.localStorage.getItem(storageCheckoutInfos);
    return storageString ? JSON.parse(storageString) : INITIAL_STORAGE
}

export function setStorageCheckout(checkoutObj = {}) {
    const actualInfos = getStorageCheckout();
    const newObj = { ...actualInfos, ...checkoutObj };
    window.localStorage.setItem(storageCheckoutInfos, JSON.stringify(newObj));
    return checkoutObj;
}

export function clearStorageCheckout() {
    return window.localStorage.setItem(storageCheckoutInfos, JSON.stringify(INITIAL_STORAGE));
}