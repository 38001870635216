// eslint-disable-next-line
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { storageCart } from '../constants/strings';
import { AppContext } from '../contexts/AppContext';

const GetCartId = () => {
    let location = useLocation();
    const cartQueryString = (new URLSearchParams(location.search)).get('cart')
    const { cart } = useContext(AppContext);
    const cartInfoString = window.localStorage.getItem(storageCart);
    let sessionId = null;
    if (cartInfoString) {
        const cartObj = JSON.parse(cartInfoString);
        sessionId = cartObj.id || null
    }

    const cartId = cartQueryString || (cart && cart.id ? cart.id : null) || sessionId;

    return cartId;
}

export default GetCartId;