import React from 'react';

export const HeaderContext = React.createContext({
    title: null,
    setTitle: (title) => { },
    showDetailCart: false,
    setShowDetailCart: (show) => { }
});

const HeaderContextProvider = ({ children, value }) => (
    <HeaderContext.Provider value={value}>
        <HeaderContext.Consumer>
            {() => children}
        </HeaderContext.Consumer>
    </HeaderContext.Provider>
);

export default HeaderContextProvider;
