import React from 'react';

export const AppContext = React.createContext({
    user: null,
    setUser: (user) => { },
    cart: null,
    setCart: (cart) => { },
    loading: false,
    setLoading: (value) => { },
    theme: {
        color: null,
    },
    setTheme: (theme) => { }
});

const Context = ({ children, value }) => (
    <AppContext.Provider value={value}>
        <AppContext.Consumer>
            {() => children}
        </AppContext.Consumer>
    </AppContext.Provider>
);

export default Context;
