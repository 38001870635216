import React from 'react';

export const StoreContext = React.createContext({
    store: null,
    setStore: () => { },
    storeList: null,
    setStoreList: () => { },
    filterType: 'store',
    setFilterType: () => { }
});

const StoreContextProvider = ({ children, value }) => (
    <StoreContext.Provider value={value}>
        <StoreContext.Consumer>
            {() => children}
        </StoreContext.Consumer>
    </StoreContext.Provider>
);

export default StoreContextProvider;
