import moment from 'moment';
import React from 'react';
import 'moment/locale/pt-br';

moment.locale('pt-br');

export const validateFields = (fields) => {
	let valid = true;
	const errors = []
	const form = {};

	for (let index = 0; index < fields.length; index++) {
		const field = fields[index];

		if (!field.required) {
			form[field.name] = field.value;
		}
		else if (field.valid) {
			form[field.name] = field.value;
		}
		else {
			valid = false;
			errors.push({ ref: field, name: field.name, value: field.value, showAs: field.showAs });
		}
	}

	return {
		valid,
		errors,
		form,
	}
}

export function renderText(text = '', props = {}) {

	if (!text || !text.length) {
		return null;
	}

	return (
		<>
			{text.split('\n').map((val, index) => {
				val = val.replace(/(\n|\\n)/g, '');
				if (val.length > 1) {
					return (<p key={`text_${val.substring(1, 10).replace(/\s/g, '')}_${index}`} {...props}>{val}</p>)
				}
				return null;
			})}
		</>
	)
}

export function checkCardNumber(number) {
	const cards = {
		visa: /^4[0-9]{12}([0-9]{3})?$/,
		mastercard: /^5[1-5][0-9]{14}$/,
		amex: /^3[47][0-9]{13}$/,
		diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
		discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
		elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
		hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
	};
	for (var card in cards) {
		if (number.match(cards[card])) {
			return card;
		}
	}
	return null;
}

export function maskCardNumber(value = '') {
	if (value) {
		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{4})(\d)/, '$1 $2');
		value = value.replace(/(\d{4})(\d)/, '$1 $2');
		value = value.replace(/(\d{4})(\d)/, '$1 $2');
	}
	return value || '';
}

export function maskCpf(value = '') {
	if (value) {
		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{3})(\d)/, '$1.$2');
		value = value.replace(/(\d{3})(\d)/, '$1.$2');
		value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	}
	return value || '';
}

export function maskPhone(value = '') {
	if (value) {
		value = value.replace(/\D/g, '');
		value = value.replace(/^(\d{2})(\d)/g, '($1)$2');
		value = value.replace(/(\d)(\d{4})$/, '$1-$2');
	}
	return value || '';
}

export function maskCep(value = '') {
	if (value) {
		value = value.replace(/\D/g, '');
		value = value.replace(/^(\d{5})(\d)/g, '$1-$2');
		value = value.replace(/(\d)(\d{3})$/, '$1-$2');
	}
	return value || '';
}

export function showPrice(price) {
	return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&/').replace('.', ',').replace('/', '.');
}

export function checkDateDeliveryAvailable(date, { id, fromDay = 0, toDay, start, end, timeLimit }, unavailableDays = [], storeTimes = []) {
	const momentDate = moment(date);
	const momentNow = moment();
	const keyDateDay = momentDate.get('day');

	let gap = toDay || 0;
	const limitDays = fromDay || null;

	if (timeLimit) {
		const momentTimeLimit = moment(`${momentNow.format('YYYY-MM-DD')} ${timeLimit}`);
		const diff = momentTimeLimit.diff(momentNow, 'minutes');

		// Já passou o horário limite de hoje
		if (diff < 0) {
			gap += 1;
		}
	}

	let block = true;

	const momentStart = start ? moment(start) : null;
	const momentEnd = end ? moment(end).add(1, 'day') : null;

	// Se está dentro das datas indisponíveis
	if (unavailableDays && unavailableDays.length) {
		for (let index = 0; index < unavailableDays.length; index++) {
			const { date } = unavailableDays[index];
			const unavailableMoment = moment(date).add(3, 'hours');
			const isSameDate = unavailableMoment.isSame(momentDate, 'day');
			if (isSameDate) {
				return false;
			}
		}
	}

	// Se a loja nao tem horario de abertura e de fechamento, bloqueia
	for (let index = 0; index < storeTimes.length; index++) {
		const { key, openAt, closeAt } = storeTimes[index];
		if (parseInt(key) === keyDateDay && (!openAt || openAt === '00:00:00') && (!closeAt || closeAt === '00:00:00')) {
			return false;
		}
	}

	if (!momentStart && !momentEnd) {
		block = false;
	}

	if (block) {
		// Se tem data start e a data é maior
		if (momentStart && !momentEnd) {
			const diffStart = momentDate.diff(momentStart, 'days');
			if (diffStart > 0) {
				block = false;
			}

		}

		// Se tem data end e a data é menor
		else if (!momentStart && momentEnd) {
			const diffEnd = momentDate.diff(momentEnd, 'days');
			if (diffEnd < 0) {
				block = false;
			}
		}

		// Se tem ambos limites, verifica se a data está dentro dos limites
		else if (momentStart && momentEnd) {
			if (momentDate.isBetween(momentStart, momentEnd)) {
				block = false;
			}
		}

		// Se a data está liberada, verifica pelos dias
		if (!block) {
			const initialDateMoment = (momentStart && momentStart.diff(momentNow, 'days') > 0) ? momentStart.clone() : momentNow.clone();
			const correctNowMoment = (momentNow.clone());
			const isTodayDate = (momentDate.clone()).add(1, 'days').diff(correctNowMoment, 'days') === 0;

			if (isTodayDate) {
				block = true;
			}

			if (!block) {
				if (gap && limitDays) {
					const momentNotVisibleDays = (initialDateMoment.clone()).add(gap, 'days');
					const momentLimitDays = (initialDateMoment.clone()).add(gap + limitDays, 'days');
					block = !momentDate.isBetween(momentNotVisibleDays, momentLimitDays)
				}

				else if (!gap && limitDays) {
					const momentLimitDays = (initialDateMoment.clone()).add(limitDays, 'days');
					block = momentDate.isAfter(momentLimitDays);
				}

				else if (gap && !limitDays) {
					const momentNotVisibleDays = (initialDateMoment.clone()).add(gap, 'days');
					block = momentDate.isAfter(momentNotVisibleDays)
				}

				else if (!gap && !limitDays) { }
			}

		}
	}

	return !block;
}

export function getDeliveryPriceAndError(order, deliveryType) {
	const { deliveries } = order;
	const delInfo = deliveryType ?
		deliveries.filter(d => d.type === deliveryType)[0] || null :
		deliveries[0] || null;

	let hasDeliveryError = false;
	let deliveryPrice = 0;

	if (!delInfo) {
		hasDeliveryError = 'Nenhum delivery atende ao endereço informado';
	}

	if (delInfo) {
		hasDeliveryError = delInfo.error || null;
		deliveryPrice = delInfo.freeDelivery ? 0 : (delInfo.value || 0);
	}

	return { hasDeliveryError, deliveryPrice }

}

/**
 * Retorna o valor de Y do objeto
 * @param {HTMLObject} obj 
 */
export function findYPos(obj) {
	let y = 0;

	if (obj) {
		while (obj) {
			y += obj.offsetTop;
			obj = obj.offsetParent;
		}

	}

	return y;
}

export function elementInViewport(el, { top = 0, left = 0, bottom = 0 }) {
	const rect = el.getBoundingClientRect();
	return (
		(rect.top >= top || rect.bottom >= bottom) &&
		rect.left >= left &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
}