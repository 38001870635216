import { stage, staticUrl } from '../config';

export const cookieName = `_${stage}restaurant`;

export const storageCheckoutInfos = `storage_${stage}_restaurant_checkout_infos`;
export const storageCheckout = `storage_${stage}_restaurant_checkout`;
export const storageCart = `storage_${stage}_restaurant_cart`;
export const storageUser = `storage_${stage}_restaurant_user`;
export const storageConfirmation = `storage_${stage}_restaurant_confirmation`;
export const storageCpfPayment = `storage_${stage}_restaurant_cpf`;

export const charloPhoneNumber = '11988943998';

export const transactionsStatus = {
    'declined': 'Transação negada',
    'cancelar': 'Transação cancelada',
    'paid': 'Transação autorizada',
    'waitting': 'Aguardando pagamento',
}

export const staticImages = {
    'thumb': `${staticUrl}/images/thumb.png`,
    'cover': `${staticUrl}/images/cover.png`,
};
