import { darken } from 'polished';
import styled, { css, keyframes } from 'styled-components';
import { white } from '../../constants/colors';
import { Button, remSizes } from '../../styles/general';

const animation = keyframes`
    0% {
        background-position: 0% 0%
    }
    100%{
        background-position: -135% 0%
    }
`;

const animationConfig = css`
    background-image: linear-gradient(
        -90deg,
        ${darken(0.08, white)} 0%,
        ${darken(0.11, white)} 23%,
        ${darken(0.15, white)} 50%,
        ${darken(0.11, white)} 75%,
        ${darken(0.08, white)} 100%
    );
    background-size: 400% 400%;
    animation: ${animation} 2s ease-in-out infinite;
`;

const defaultShadow = css`
    box-shadow: 1px 1px 4px -1px rgba(0,0,0,.2);
`;

export const ButtonLoading = styled(Button)`
    ${animationConfig}
    ${defaultShadow}
`;

export const ImageLoading = styled.div`
    height: ${(props) => props.height || '140px'};
    width: 100%;

    ${animationConfig}
    ${defaultShadow}
`;

export const TextLoading = styled.div`
    height: ${(props) => remSizes[props.size || '14']}rem;
    width: 100%;

    ${animationConfig}
    ${defaultShadow}
`;
